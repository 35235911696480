import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import Colgroup from '../../../components/Colgroup';

export default function FeaturesTable({ products }) {
  // list of all feature labels, each one gets a row in the table
  const allFeatures = [
    ...new Set(
      products
        .map(({ features }) => features)
        .flat()
        .map(({ label }) => label),
    ),
  ];
  // calculate column widths based on MUI 12-column grid
  const columnCount = products.length + 2;
  const productColWidth = `${(100 / 12) * (8 / products.length)}%`;
  let colWidths = Array(columnCount).fill(productColWidth);
  // first column is 2 grid units wide:
  colWidths[0] = `${100 / 6}%`;

  // for each row, display a cell for every product's value for that feature label
  const featuresByProduct = allFeatures.reduce((accum, featureLabel) => {
    const featuresByProduct = products.map(
      (product) => product.features.find(({ label }) => label === featureLabel)?.value || '',
    );
    accum[featureLabel] = featuresByProduct;
    return accum;
  }, {});

  return (
    <>
      <Grid container spacing={4} mb={1}>
        <Grid item xs={2}></Grid>
        <Grid item xs={10}>
          <Typography
            variant="h4"
            component="h3"
            fontWeight={700}
            fontSize="1.125rem"
            sx={{ pl: 3 }}
          >
            Product Features
          </Typography>
        </Grid>
      </Grid>

      <TableContainer>
        <Table sx={{ minWidth: 650, fontSize: 16 }} aria-label="Product features table">
          <Colgroup cols={colWidths} />
          <TableHead>
            <TableRow>
              <TableCell />
              {products.map((product, idx) => (
                <TableCell
                  key={`features-head-${idx}`}
                  sx={{
                    pl: 4,
                    pr: 4,
                    fontSize: '1rem',
                    fontWeight: 600,
                    verticalAlign: 'top',
                  }}
                >
                  {product.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(featuresByProduct).map((feature) => (
              <TableRow key={feature}>
                <TableCell
                  component="th"
                  variant="head"
                  scope="row"
                  sx={{
                    bgcolor: 'svce.darkBlue',
                    color: 'common.white',
                    fontWeight: 600,
                    fontSize: '1rem',
                  }}
                >
                  {feature}
                </TableCell>
                {featuresByProduct[feature].map((productFeature, idx) => (
                  <TableCell sx={{ fontSize: '1rem', pl: 4 }} key={feature + idx}>
                    {productFeature}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
