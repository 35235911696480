import { Box, useMediaQuery, useTheme } from '@mui/material';
import DetailsDrawer from '../../../components/DetailsDrawer';
import useDetailsDrawer from '../../../hooks/useDetailsDrawer';
import CompareHeader from './CompareHeader';
import FeaturesTable from './FeaturesTable';
import MobileCompareContent from './MobileCompareContent';
import OfferButtons from './OfferButtons';
import ProductsGrid from './ProductsGrid';

export default function Compare({ products, heading, backUrl }) {
  const {
    isOpen: isDrawerOpen,
    setIsOpen: setIsDrawerOpen,
    detailedItem,
    detailedItemType,
    clearDetailedItem,
  } = useDetailsDrawer();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box py={{ xs: 2, md: 4 }}>
      <CompareHeader backUrl={backUrl} heading={heading} />

      {isSmallScreen ? (
        <MobileCompareContent products={products} setIsDrawerOpen={setIsDrawerOpen} />
      ) : (
        <Box px={2}>
          <ProductsGrid products={products} setIsDrawerOpen={setIsDrawerOpen} />
          <FeaturesTable products={products} />
          <OfferButtons products={products} setIsDrawerOpen={setIsDrawerOpen} />
        </Box>
      )}

      <DetailsDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        detailedItemType={detailedItemType}
        detailedItem={detailedItem}
        clearDetailedItem={clearDetailedItem}
      />
    </Box>
  );
}
