import { Link as InertiaLink } from '@inertiajs/react';
import { Link as MuiLink } from '@mui/material';

export default function Link({ children, external = false, ...props }) {
  return (
    <MuiLink
      component={external ? 'a' : InertiaLink}
      target={external ? '_blank' : '_self'}
      {...props}
    >
      {children}
    </MuiLink>
  );
}
