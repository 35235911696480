import { Box, Button, Container, Typography } from '@mui/material';
import { useState } from 'react';
import WaterHeater from './WaterHeater';
import HeatPump from './HeatPump';
import InductionCooking from './InductionCooking';
import YardCare from './YardCare';
import ElectricDryer from './ElectricDryer';
import SmartThermostat from './SmartThermostat';
import Lighting from './Lighting';
import DropdownInput from '../../../components/DropdownInput';

const Tab = ({ name, idx, isSelected, onClick }) => (
  <Button
    id={`tab-${idx}`}
    type="button"
    role="tab"
    aria-controls={`tabpanel-${idx}`}
    aria-selected={isSelected ? 'true' : 'false'}
    onClick={onClick}
    sx={{
      p: 1.25,
      borderRadius: '6px',
      backgroundColor: isSelected ? 'gray.200' : 'transparent',
      color: isSelected ? 'primary.main' : 'common.white',
      border: 'none',
      fontWeight: 600,
      lineHeight: '20px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: isSelected ? 'gray.300' : 'transparent',
      },
    }}
  >
    {name}
  </Button>
);

const TABS = [
  { name: 'Heat Pump Water Heaters', content: <WaterHeater /> },
  { name: 'Heat Pump Heating & Cooling', content: <HeatPump /> },
  { name: 'Induction Cooking', content: <InductionCooking /> },
  { name: 'Yard Care Equipment', content: <YardCare /> },
  { name: 'Electric Dryer', content: <ElectricDryer /> },
  { name: 'Smart Thermostat', content: <SmartThermostat /> },
  { name: 'Lighting & Other Efficiency Measures', content: <Lighting /> },
];

export default function Appliances() {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <Box bgcolor="svce.darkBlue" py={{ xs: 4, md: 7 }}>
      <Container maxWidth="xl">
        <Typography
          variant="h2"
          color="common.white"
          fontWeight={600}
          fontSize={{ xs: '2rem', md: '3rem' }}
          lineHeight="40px"
          textAlign="center"
          mb={4}
        >
          Learn more about popular home appliances
        </Typography>

        <Box
          component="nav"
          role="tablist"
          sx={{
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'center',
            mb: 6,
            gap: 1,
          }}
        >
          {TABS.map(({ name }, idx) => (
            <Tab
              key={idx}
              idx={idx}
              name={name}
              isSelected={selectedTab === idx}
              onClick={() => setSelectedTab(idx)}
            />
          ))}
        </Box>

        <Box display={{ xs: 'block', md: 'none' }}>
          <DropdownInput
            value={selectedTab}
            options={TABS.map(({ name }, idx) => ({ value: idx, children: name }))}
            onChange={(e) => setSelectedTab(e.target.value)}
            sx={{
              mb: 4,
              '& .MuiInputBase-root': {
                p: 1,
                borderRadius: '4px',
              },
            }}
          />
        </Box>

        {TABS.map(({ content }, idx) => (
          <Box
            key={idx}
            component="section"
            id={`tabpanel-${idx}`}
            role="tabpanel"
            aria-labelledby={`tab-${idx}`}
            sx={{ display: selectedTab !== idx ? 'none' : 'block' }}
          >
            {content}
          </Box>
        ))}
      </Container>
    </Box>
  );
}
