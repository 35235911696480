import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link as InertiaLink } from '@inertiajs/react';
import PlaceholderImage from '../assets/images/placeholder.jpg';
import SvceButton from './SvceButton';
import { getDetailsUrl } from '../utils/urlFunctions';

export default function ResourceCard({ resource, setIsDrawerOpen }) {
  const { id, name, short_description: shortDescription, resourceCardCta } = resource;

  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const url = getDetailsUrl({ id, type: 'resource' });

  return (
    <Box
      sx={{
        p: 0,
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        width: '100%',
        height: { xs: '100%', sm: 'auto' },
        border: '1px solid',
        borderColor: 'gray.200',
        borderRadius: theme.shape.resourceCardBorderRadius,
        overflow: 'hidden',
      }}
    >
      <Box
        component="img"
        src={PlaceholderImage}
        sx={{
          flexShrink: 0,
          width: isXSmallScreen ? '100%' : 189,
          height: isXSmallScreen ? 153 : 'auto',
          minHeight: { sm: '100%' },
          maxHeight: { xs: 153, sm: 'none' },
          objectFit: 'cover',
        }}
      />

      <Box
        sx={{
          py: 2.5,
          px: { xs: 2.5, sm: 5 },
          backgroundColor: theme.palette.common.white,
          alignSelf: 'stretch',
          flexGrow: 1,
        }}
      >
        <Typography variant="h5" fontSize="1rem" fontWeight="bold" mb={{ xs: 1, sm: 0.75 }}>
          {name}
        </Typography>
        <Typography
          fontSize="0.875rem"
          lineHeight="20px"
          component="p"
          mb={2}
          color={theme.palette.svce.darkGrey}
        >
          {shortDescription}
        </Typography>

        <SvceButton
          component={InertiaLink}
          href={url}
          preserveState={true}
          preserveScroll={true}
          sx={{ minWidth: '125px' }}
          onClick={() => {
            setIsDrawerOpen(true);
          }}
        >
          {resourceCardCta || 'View'}
        </SvceButton>
      </Box>
    </Box>
  );
}
