import { Box, Typography } from '@mui/material';

export default function MobileFeaturesTable({ product }) {
  return (
    <Box>
      <Typography variant="h4" component="h3" fontWeight="bold" mb={2}>
        {product.name}
      </Typography>
      {product.features.map(({ label, value }) => (
        <Box key={label} display="flex">
          <Box
            flex={1}
            bgcolor="blue.500"
            py={2}
            px={1}
            display="flex"
            alignItems="center"
            borderBottom="1px solid"
            borderColor="common.white"
          >
            <Typography color="common.white">{label}</Typography>
          </Box>
          <Box
            flex={1}
            display="flex"
            alignItems="center"
            py={2}
            px={{ xs: 1, sm: 2 }}
            borderBottom="1px solid"
            borderColor="gray.300"
          >
            <Typography>{value}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
