import { Box, Grid, Typography } from '@mui/material';
import HeadingWithFlair from '../../../components/HeadingWithFlair';
import FilterRail from '../../../components/Filters/FilterRail';
import MobileFilters from '../../../components/Filters/MobileFilters';
import SvceButton from '../../../components/SvceButton';
import SpeakWithAnAdvisor from '../../../components/SpeakWithAnAdvisor';
import ResourceList from '../../Recommendations/Index/ResourceList';

export default function RecommendationsIndex({
  filters_data: filtersData
}) {
  return (
    <Box>
      <Box
        display={{ xs: 'flex', md: 'none' }}
        alignItems="center"
        justifyContent="flex-end"
        borderBottom="1px solid"
        borderColor="gray.200"
        py={2}
        px={3}
      >
        <MobileFilters filtersData={filtersData} variant="static" />
      </Box>

      <Box sx={{ p: { xs: 3, sm: 7 }, pb: { xs: 3, sm: 5 } }}>
        <HeadingWithFlair>
          Business & Contractors Resources
        </HeadingWithFlair>
        <Typography>
          Take advantage of electrification incentives for your business, properties, or contractor installations. 
        </Typography>
      </Box>
      <Box sx={{ p: { xs: 3, sm: 7 }, pb: { xs: 3, sm: 5 } }}>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} md={4} xl={3} display={{ xs: 'none', md: 'flex' }}>
            <FilterRail
              title="Your Electrification Project"
              description="Filter available SVCE programs, incentives, and resources based on your need."
              filtersData={filtersData}
            />
          </Grid>

          <Grid item xs={12} md={8} xl={9}>
            <ResourceList />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
