import { Box, Typography } from '@mui/material';
import Link from '../Link';
import NoResults from './NoResults';

export default function Products({ products, handleLinkClick }) {
  if (!products.length) {
    return <NoResults handleLinkClick={handleLinkClick} />;
  }

  return (
    <>
      {products.map((product) => (
        <Box key={product.id} mb="18px" display="flex" alignItems="center">
          <Box
            height={72}
            width={72}
            flexShrink={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              component="img"
              alt={product.name}
              src={product.imageUrl}
              maxHeight={72}
              maxWidth={72}
              mr={2}
            />
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Link
              href={`/products?detailed_item_id=${product.id}&detailed_item_type=product`}
              onClick={handleLinkClick}
            >
              <Typography
                variant="h4"
                component="span"
                color="blue.500"
                fontWeight={600}
                fontSize="1.125rem"
              >
                {product.name}
              </Typography>
            </Link>
            <Typography color="gray.600" component="span">
              {product.modelNumber}
            </Typography>
            <Typography component="span" fontWeight={600}>
              {product.salePrice}
            </Typography>
          </Box>
        </Box>
      ))}
    </>
  );
}
