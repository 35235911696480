import { Box, Typography, Button } from '@mui/material';
import { MinusCircleIcon, PlusCircleIcon } from '../assets/icons/icons';
import React, { useState } from 'react';

export default function Accordion({ title, children, borderBottom = true }) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Box
      width="100%"
      borderBottom={borderBottom ? '1px solid' : 'none'}
      borderColor="gray.200"
      py={2}
    >
      <Button
        aria-label={isExpanded ? 'Collapse accordion' : 'Expand accordion'}
        onClick={() => setIsExpanded((prev) => !prev)}
        sx={{
          width: '100%',
          px: 0,
          color: 'common.black',
          textTransform: 'none',
          textAlign: 'left',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ flexGrow: 1, fontWeight: 600 }}>{title}</Typography>
        <Box sx={{ flexShrink: 0, ml: 1 }}>
          {isExpanded ? <MinusCircleIcon /> : <PlusCircleIcon />}
        </Box>
      </Button>

      <Box
        sx={{ overflow: 'hidden', transition: 'all 300ms ease', maxHeight: isExpanded ? 1000 : 0 }}
      >
        {children}
      </Box>
    </Box>
  );
}
