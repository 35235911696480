import { Box, Container, Typography } from '@mui/material';
import Accordion from '../../../components/Accordion';

export default function Steps() {
  return (
    <Container maxWidth="lg" sx={{ mb: 4 }}>
      <Typography variant="h2" fontWeight={600} fontSize="2rem">
        Steps to a Home Upgrade
      </Typography>
      <Accordion title="1. Assess your home">
        <Box>
          <Typography mb={1}>
            Start with a detailed analysis of your home's energy consumption and get personalized
            recommendations from an energy professional. Receive a virtual energy audit from our Go
            Electric Advisor service.
          </Typography>
          <Typography>
            Next, take inventory of your gas appliances, equipment, and vehicles to identify which
            are nearing the end of their life and the biggest energy consumers. For renters, note
            which appliances you can replace yourself, which may require owner approval, and
            consider implementing efficiency measures like insulation or weatherstripping.
          </Typography>
        </Box>
      </Accordion>

      <Accordion title="2. Conduct a home health check">
        <Box>
          <Typography>
            Before upgrading to new electric appliances, ensure your home is energy-efficient and
            healthy. Have a contractor seal air leaks and address any mold or pest issues. If your
            home was built before 1979, it may require significant upgrades to the electric panel
            and wiring to meet increased electric demands.
          </Typography>
        </Box>
      </Accordion>

      <Accordion title="3. Plan your home upgrade and explore incentives">
        <Box>
          <Typography mb={1}>
            Identify which home appliances are ready for an upgrade to electric. Major equipment
            like clothes dryers, water heaters, space heating and cooling systems, and kitchen
            appliances all have efficient electric equivalents. Consider pairing these upgrades with
            technologies such as EV charging or solar + battery storage to maximize your savings and
            improve energy efficiency. Our Go Electric Advisor can help develop a detailed plan,
            prioritize upgrades, and offer tailored recommendations.
          </Typography>
          <Typography>
            To make your transition smoother, explore our range of incentives, including rebates and
            full-service support, to lower your upfront costs (see table below). Regional, state,
            and federal programs offer significant savings through rebates, tax credits, and
            financing options.
          </Typography>
        </Box>
      </Accordion>

      <Accordion title="4. Install your new electric appliance">
        <Box>
          <Typography>
            Select the level of support you need for your home upgrade (see table below). SVCE
            provides options to connect you with local contractors who can install your electric
            appliance. Ensure that you or your contractor apply for the necessary building permits
            with your city or county to qualify for rebates.
          </Typography>
        </Box>
      </Accordion>

      <Accordion title="5. Evaluate your electricity rate plan">
        <Box>
          <Typography>
            Customers can benefit from greater energy cost savings by pairing home upgrades with an
            EV or Electric Home (E-ELEC) rate. This rate provides significant discounts on off-peak
            energy usage. Learn more about these savings and rates here.
          </Typography>
        </Box>
      </Accordion>
      <Accordion title="6. Shut off the gas!">
        <Box>
          <Typography>
            Once your home is fully electric, you can turn off your PG&E gas service permanently.
            Say goodbye to high gas bills, and sleep more soundly knowing your home is forever safe
            from gas leaks and pollutants.
          </Typography>
        </Box>
      </Accordion>
    </Container>
  );
}
