import { Box, Container, Typography } from '@mui/material';
import Accordion from '../../../components/Accordion';

export default function Faq() {
  return (
    <Container>
      <Typography variant="h2" fontSize="3rem" mb={2} textAlign="center">
        F.A.Q.
      </Typography>
      <Typography mb={1} textAlign="center">
        These are some of the most common questions we receive from the community. We have plenty of
        resources to share if you can't find something here. Reach out to us. We're here to help.
      </Typography>

      <Accordion title="Do you vet contractors or do clients have to do that?">
        <Typography>
          We do not vet the contractors directly. However, we provide a list of contractors who have
          completed multiple electrification projects for customers receiving SVCE rebates. We also
          guide customers to contractor finder tools like{' '}
          <a
            href="https://switchison.cleanenergyconnection.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            "The Switch Is On,"
          </a>{' '}
          which include contractor ratings, such as Google reviews, and information about rebate
          program eligibility. These resources are available on our{' '}
          <a
            href="https://svcleanenergy.org/find-contractor"
            target="_blank"
            rel="noopener noreferrer"
          >
            Find a Contractor
          </a>{' '}
          page.
        </Typography>
      </Accordion>

      <Accordion title="Can you help review bids?">
        <Typography>
          Yes, our{' '}
          <a href="https://goelectric.svcleanenergy.org/" target="_blank" rel="noopener noreferrer">
            Go Electric Advisors
          </a>{' '}
          can review bids to help customers understand the project's scope and assess whether the
          proposed costs are reasonable, considering local equipment and labor costs.
        </Typography>
      </Accordion>

      <Accordion title="How much support do you provide through a home upgrade project?">
        <Typography>
          Depending on the option selected, residents will receive different levels of support.
          Rebates have the least direct support and Full-Service has the most.
        </Typography>
      </Accordion>

      <Accordion title="Do you provide a free energy assessment?">
        <Typography>
          Yes, our Go Electric Advisor service provides free virtual audits and whole-home
          electrification plans. These assessments include gathering information about the building
          and existing equipment (e.g., fuel type and age) and recommending upgrades, including
          electrification and energy efficiency measures. Our Advisors will also collect information
          about the electrical panel to optimize panel capacity. Customers may also use services
          offered through PG&E such as{' '}
          <a
            href="https://www.hea.com/hea-web/P4P-010.jspage?prepareForm=true&loc=3P"
            target="_blank"
            rel="noopener noreferrer"
          >
            Home Intel
          </a>
          .
        </Typography>
      </Accordion>

      <Accordion title="Do contractors pay you a commission?">
        <Typography>
          No, they do not. In fact, SVCE offers a{' '}
          <a
            href="https://svcleanenergy.org/futurefit-fundamentals/#collaboration"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contractor Training
          </a>{' '}
          program which pays contractors $500 when they complete an online course to learn about
          electric equipment, and they can receive an additional bonus if they refer SVCE customers
          to our rebates.
        </Typography>
      </Accordion>

      <Accordion title="How much are your rebates? Are they income-adjusted?" borderBottom={false}>
        <Typography>
          Customers can receive up to $8,750 in{' '}
          <a
            href="https://svcleanenergy.org/home-rebates"
            target="_blank"
            rel="noopener noreferrer"
          >
            rebates
          </a>{' '}
          for upgrading to new electric home equipment, such as heat pump water heaters, heat pump
          heating and cooling systems, induction cooktops/ranges, and pre-wiring for future
          upgrades. An extra $4,250 in rebates is available for income-qualified customers.
        </Typography>
      </Accordion>
    </Container>
  );
}
