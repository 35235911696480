import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

export default function SelectFilter({
  id,
  name,
  options,
  value,
  title,
  onChange,
  includeBlank = true,
}) {
  return (
    <Box mb={3} mt={title ? 0 : 3}>
      {title && (
        <Typography fontSize="1rem" component="h3" fontWeight={600} pb={5} pt={4}>
          {title}
        </Typography>
      )}
      <FormControl fullWidth>
        <InputLabel id={id}>{name}</InputLabel>
        <Select
          labelId={id}
          value={value}
          label={name}
          onChange={(e) => onChange(id, e.target.value)}
        >
          {includeBlank && (
            <MenuItem value="">
              <em>Select a value</em>
            </MenuItem>
          )}
          {options.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
