import { usePage } from '@inertiajs/react';
import { Box, Container, Grid, Typography } from '@mui/material';
import ButtonLink from '../../../components/ButtonLink';

export default function HelpLinks() {
  const {
    props: { solar_panels_image: solarPanelsImage, battery_image: batteryImage },
  } = usePage();

  return (
    <Container maxWidth="xl" sx={{ mb: { xs: 5, md: 10 } }}>
      <Grid container spacing={{ xs: 2, md: 6 }}>
        <Grid item xs={12} md={6} display="flex" alignItems="center" order={{ xs: 2, md: 1 }}>
          <Box display="flex" flexDirection="column" maxWidth={{ xs: 'none', md: 500 }}>
            <Typography variant="h1" fontSize="1.875rem" fontWeight={600} mb={2}>
              Solar Panels + Battery Storage
            </Typography>
            <Typography mb={1}>
              Curious how much you could save with a solar + battery system? The Solar + Battery
              Assistant can help. Energy advisors are available to help you evaluate multiple bids,
              understand the different technologies and explore purchase and financing options.
              Start generating your own clean electricity, today.
            </Typography>
            <Typography mb={{ xs: 2, md: 4 }}>
              SVCE customers also qualify for an extra $1,000 rebate on a main electrical panel
              upgrade, if needed, when installing a solar and battery system through the Solar+
              Battery Assistant. 
            </Typography>
            <ButtonLink
              external
              href="https://solar.svcleanenergy.org/"
              sx={{ alignSelf: 'flex-start' }}
            >
              Solar + Battery Assistant
            </ButtonLink>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
          <Box width="100%" height="100%" display="flex" alignItems="center">
            <Box
              component="img"
              src={solarPanelsImage}
              alt="A house with solar panels"
              sx={{ width: '100%', maxHeight: { xs: 250, md: 480 }, objectFit: 'cover' }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} order={{ xs: 4, md: 3 }}>
          <Box width="100%" height="100%" display="flex" alignItems="center">
            <Box
              component="img"
              src={batteryImage}
              alt="A home with a battery storage solution"
              sx={{ width: '100%', maxHeight: { xs: 250, md: 480 }, objectFit: 'cover' }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display="flex" alignItems="center" order={{ xs: 4, md: 3 }}>
          <Box display="flex" flexDirection="column" maxWidth={{ xs: 'none', md: 500 }}>
            <Typography variant="h1" fontSize="1.875rem" fontWeight={600} mb={2}>
              Battery Storage
            </Typography>
            <Typography mb={1}>
              Solar only works when it's sunny? There's a battery out there that disagrees. Add
              batteries to your existing solar system to store energy for use during peak evening
              hours, helping you avoid higher electricity rates. And you can keep the power on for
              certain home appliances, if the power goes out.
            </Typography>
            <Typography mb={{ xs: 2, md: 4 }}>
              SVCE customers also qualify for an extra $1,000 rebate on a main electrical panel
              upgrade, if needed, when installing battery storage through the Battery Assistant.
            </Typography>
            <ButtonLink
              external
              href="https://solar.svcleanenergy.org/"
              sx={{ alignSelf: 'flex-start' }}
            >
              Solar + Battery Assistant
            </ButtonLink>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
