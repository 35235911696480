import { Button, Typography } from '@mui/material';
import { UpArrowsIcon } from '../assets/icons/icons';
import { useEffect, useState } from 'react';

export default function ScrollToTop() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setShowButton(scrollY > window.innerHeight);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const ariaProps = {
    'aria-hidden': 'true',
    focusable: 'false',
  };

  return (
    <>
      {
        <Button
          sx={{
            display: { xs: 'flex', md: 'none' },
            flexDirection: 'column',
            position: 'fixed',
            bottom: 90,
            left: -10,
            width: 58,
            height: 73,
            borderRadius: '5px',
            backgroundColor: 'primary.main',
            transition: 'opacity 0.3s ease-in-out',
            boxShadow: 7,
            opacity: showButton ? 1 : 0,
            pointerEvents: showButton ? 'auto' : 'none',
            '&:hover, &:focus': {
              backgroundColor: 'primary.main',
            },
            '&:focus-visible': {
              outline: '2px solid',
            },
          }}
          aria-label="Scroll to top"
          onClick={scrollToTop}
        >
          <UpArrowsIcon {...ariaProps} />
          <Typography
            component="span"
            fontSize="0.75rem"
            fontWeight={700}
            textTransform="none"
            color="common.white"
            lineHeight="normal"
            maxWidth="40px"
            mt={0.5}
          >
            Scroll to top
          </Typography>
        </Button>
      }
    </>
  );
}
