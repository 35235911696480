import Hero from './Hero';
import Resources from './Resources';
import { Box } from '@mui/material';

export default function Landing({ resources, hero_image: heroImage, text: { copy } }) {
  return (
    <Box pb={6}>
      <Hero imagePath={heroImage} copy={copy.hero} />
      <Resources resources={resources} copy={copy.resources} />
    </Box>
  );
}
