import { Grid } from '@mui/material';
import { router } from '@inertiajs/react';
import SvceButton from '../../../components/SvceButton';
import { getDetailsUrl } from '../../../utils/urlFunctions';

const getOfferText = (product) =>
  `${product.offers.length} ${product.offers.length === 1 ? 'offer' : 'offers'} from ${
    product.salePrice
  }`;

export default function OfferButtons({ products, setIsDrawerOpen }) {
  const handleClick = (id) => {
    setIsDrawerOpen(true);
    const url = getDetailsUrl({ id, type: 'product' });
    router.visit(url, { preserveScroll: true, preserveState: true });
  };

  return (
    <Grid container spacing={4} mt={5}>
      <Grid item xs={2} />
      {products.map((product) => (
        <Grid key={product.id} item xs={products.length === 2 ? 4 : 2.66}>
          <SvceButton
            onClick={() => handleClick(product.id)}
            aria-label={`See details for ${product.name}`}
            sx={{
              width: '100%',
            }}
          >
            {getOfferText(product)}
          </SvceButton>
        </Grid>
      ))}
      <Grid item xs={2} />
    </Grid>
  );
}
