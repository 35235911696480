import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const CustomButton = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
  fontSize: '14px',
  padding: '10px 32px',
  borderRadius: '8px',
  lineHeight: '20px',
  fontWeight: 600,
  textTransform: 'none',
  '&.MuiButton-sizeLarge': {
    lineHeight: '30px',
  }
}));

export default function SvceButton({ children, color = "svceGreen", ...rest }) {
  const parsedColor = color === "white" ? "whiteButton" : color;
  return (
    <CustomButton variant="contained" size="medium" color={parsedColor} {...rest}>
      {children}
    </CustomButton>
  );
}
