import { Box, Button, useTheme } from '@mui/material';

export default function MobileOffersButton({ ctaText, onClick }) {
  const theme = useTheme();

  return (
    <Box
      display={{ xs: 'flex', sm: 'none' }}
      position="fixed"
      bottom={0}
      width="100%"
      backgroundColor="common.white"
      justifyContent="center"
      px={2}
      pb={2}
      py={3}
      boxShadow={theme.shadows[4]}
    >
      <Button
        onClick={onClick}
        sx={{
          borderRadius: 0,
          width: '100%',
          backgroundColor: 'primary.main',
          py: 1.25,
          color: 'common.white',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          '&:hover, &:focus': {
            backgroundColor: 'green.600',
          },
        }}
      >
        {ctaText}
      </Button>
    </Box>
  );
}
