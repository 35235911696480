import { Typography } from '@mui/material';
import { usePage } from '@inertiajs/react';
import ApplianceTab from './ApplianceTab';

export default function Lighting() {
  const {
    props: { family_image: familyImage },
  } = usePage();

  return (
    <ApplianceTab
      heading="Lighting & General Efficiency"
      img={familyImage}
      imgAlt="Image of a family"
      testimonial="“SVCE's FutureFit Rebate Program spurred me into action! We replaced our gas water heater with a heat pump water heater and reduced our gas usage and GHG emissions.”"
    >
      <Typography mb={2}>
        When you switch from gas to electric appliances you reduce emissions and may save money.
        Take one more step towards minimizing your energy usage: Install energy efficient LED light
        bulbs, weather stripping, insulation, and smart electronics, like thermostats and power
        strips.
      </Typography>
      <Typography>Learn more about maximizing energy efficiency in your home.</Typography>
    </ApplianceTab>
  );
}
