import { Typography } from '@mui/material';
import { usePage } from '@inertiajs/react';
import ApplianceTab from './ApplianceTab';

export default function SmartThermostat() {
  const {
    props: { thermostat_image: thermostatImage },
  } = usePage();

  return (
    <ApplianceTab
      heading="Smart Thermostat"
      img={thermostatImage}
      imgAlt="Image of a smart thermostat"
    >
      <Typography mb={2}>
        A smart thermostat is a cost-effective way to save on heating and cooling costs. It learns
        your routines, lets you control the temperature from your smartphone, and automatically
        adjusts to save energy when you're away. Forget to turn off the thermostat? Your smart
        thermostat will have you covered.
      </Typography>
      <Typography>
        Learn more about smart thermostats with The Basics, shop for one and find incentives on the
        Appliances Marketplace.
      </Typography>
    </ApplianceTab>
  );
}
