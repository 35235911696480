import { Typography, useTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';

export default function HeadingWithFlair({ children, sx = {}, ...rest }) {
  const theme = useTheme();
  const sxOptions = deepmerge({
    position: 'relative',
    fontSize: {
      xs: '32px',
      md: theme.typography.h1.fontSize,
    },
    mt: `calc(${theme.spacing(2)} + 15px)`,
    mb: theme.spacing(2),
    "&::before": {
      content: "''",
      display: 'block',
      position: 'absolute',
      width: '250px',
      height: '15px',
      backgroundColor: theme.palette.primary.main,
      top: `calc(-${theme.spacing(2)} - 15px)`,
      left: 0,
    },
  }, sx);
  return <Typography variant="h1" sx={sxOptions} {...rest}>{children}</Typography>
}
