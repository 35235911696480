import { Box, useTheme } from '@mui/material';
import { Header } from './Header';
import { Footer } from './Footer';
import WelcomeBanner from '../../pages/Landing/Index/WelcomeBanner';
import { Head, usePage } from '@inertiajs/react';

function SvceLayout({ children }) {
  const theme = useTheme();
  const {
    props: { title, meta = [] },
  } = usePage();

  return (
    <>
      <Head>
        {[
          title && <title key={title}>{title}</title>,
          ...meta.map(({ ...attrs }) => <meta key={attrs.name || attrs.property} {...attrs} />),
        ].filter((tag) => tag)}
      </Head>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header />
        <Box sx={{ p: { xs: 1, sm: 3 } }}>
          <WelcomeBanner />
          <Box
            sx={{
              backgroundColor: theme.palette.common.white,
              borderRadius: theme.shape.svceBorderRadius,
            }}
          >
            {children}
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

export default (page) => <SvceLayout>{page}</SvceLayout>;
