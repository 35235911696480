import { Box, Container, Typography, useTheme } from '@mui/material';
import HeadingWithFlair from '../../../components/HeadingWithFlair';
import Link from '../../../components/Link';
import { LeftArrowIcon } from '../../../assets/icons/icons';
import Steps from './Steps';
import Faq from './Faq';
import Table from './Table';
import HelpLinks from './HelpLinks';
import { useRef } from 'react';
import SvceButton from '../../../components/SvceButton';

export default function HomeUpgrade({ hero_image: heroImage, back_url: backUrl }) {
  const theme = useTheme();

  const tableRef = useRef();

  const handleLearnMoreClick = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box py={{ xs: 0, md: 3, lg: 6 }}>
      <Box display="flex" flexDirection="column">
        <Box order={{ xs: 2, md: 1 }}>
          <Container maxWidth="xl">
            <Link
              href={backUrl}
              sx={{
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                py: 3,
              }}
            >
              <Box mr={1}>
                <LeftArrowIcon fill={theme.palette.primary.main} />
              </Box>
              <Typography
                variant="h4"
                component="p"
                fontWeight={700}
                fontSize="0.875rem"
                textTransform="uppercase"
              >
                Back
              </Typography>
            </Link>
            <HeadingWithFlair>Home Upgrade</HeadingWithFlair>
            <SvceButton onClick={handleLearnMoreClick} sx={{ width: 230, mb: { xs: 3, md: 7 } }}>
              Learn More
            </SvceButton>
          </Container>
        </Box>

        <Box width="100%" order={{ xs: 1, md: 2 }}>
          <Box
            component="img"
            alt=""
            src={heroImage}
            sx={{
              objectFit: 'cover',
              objectPosition: 'bottom',
              width: '100%',
              height: '100%',
              maxHeight: { xs: 240, md: 545 },
              borderTopLeftRadius: { xs: 50, md: 0 },
              borderTopRightRadius: { xs: 50, md: 0 },
            }}
          />
        </Box>
      </Box>

      <Container maxWidth="md">
        <Box my={{ xs: 3, md: 6 }}>
          <Typography variant="h2" fontWeight={600} fontSize="2rem">
            Why upgrade your home?
          </Typography>
          <Typography fontSize={{ xs: '1rem', md: '1.25rem' }} my={2}>
            Transform your home with electric appliances that boost comfort, increase efficiency,
            save energy, and create a healthier living environment. Enjoy up to $8,750 in rebates
            for upgrading to new electric appliances, with an extra $4,250 available for
            income-qualified residents.
          </Typography>
          <Typography fontSize={{ xs: '1rem', md: '1.25rem' }}>
            Whether you're renovating, building, or purchasing a new home or appliance, explore our
            comprehensive incentives, including generous rebates and full-service options, to make
            your upgrade process seamless and rewarding.
          </Typography>
        </Box>
      </Container>

      <Steps />

      <Box ref={tableRef}>
        <Table />
      </Box>

      <HelpLinks />

      <Faq />
    </Box>
  );
}
