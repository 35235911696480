import { Box } from '@mui/material';
import { useRef, useState } from 'react';
import MobileOffersButton from './MobileOffersButton';
import ProductDetailsAccolades from './ProductDetailsAccolades';
import ProductDetailsFeatures from './ProductDetailsFeatures';
import ProductDetailsHeader from './ProductDetailsHeader';
import ProductDetailsOffers from './ProductDetailsOffers';
import ProductDetailsResource from './ProductDetailsResource';
import ProductDetailsSavings from './ProductDetailsSavings';
import ProductDetailsSimilarProducts from './ProductDetailsSimilarProducts';

export default function ProductDetails({ product, onDrawerClose, onShowResource }) {
  const { offers, salePrice, resource, secondaryResource } = product;

  const wrapperRef = useRef(null);
  const offersRef = useRef(null);

  const offersCtaText = `${offers.length} ${
    offers.length === 1 ? 'offer' : 'offers'
  } from ${salePrice}`;

  const handleMobileOffersClick = () => {
    if (offersRef.current) {
      offersRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Box p={{ xs: 2, sm: 5.5 }} ref={wrapperRef}>
        <ProductDetailsHeader product={product} onDrawerClose={onDrawerClose} />
        <ProductDetailsAccolades product={product} />
        <ProductDetailsSavings product={product} />

        {resource && (
          <Box mb={3}>
            <ProductDetailsResource
              resource={resource}
              onDrawerClose={onDrawerClose}
              onShowResource={onShowResource}
            />
          </Box>
        )}

        <ProductDetailsOffers product={product} ref={offersRef} />
        {secondaryResource && (
          <Box mb={4}>
            <ProductDetailsResource
              resource={secondaryResource}
              onDrawerClose={onDrawerClose}
              onShowResource={onShowResource}
            />
          </Box>
        )}
        <ProductDetailsFeatures product={product} />
      </Box>
      <Box
        p={{ xs: 2, sm: 5.5 }}
        pt={{ xs: 5, sm: 5.5 }}
        backgroundColor="gray.200"
        mb={{ xs: 11, sm: 0 }}
      >
        <ProductDetailsSimilarProducts product={product} wrapperRef={wrapperRef} />
      </Box>

      <MobileOffersButton ctaText={offersCtaText} onClick={handleMobileOffersClick} />
    </>
  );
}
