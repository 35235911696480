import { createTheme, lighten } from '@mui/material/styles';
import ProximaNovaRegular from '../assets/fonts/ProximaNovaRegular.ttf'
import ProximaNovaRegularItalic from '../assets/fonts/ProximaNovaRegularItalic.ttf'
import ProximaNovaMedium from '../assets/fonts/ProximaNovaMedium.ttf'
import ProximaNovaSemibold from '../assets/fonts/ProximaNovaSemibold.ttf'
import ProximaNovaSemiboldItalic from '../assets/fonts/ProximaNovaSemiboldItalic.ttf'
import ProximaNovaBold from '../assets/fonts/ProximaNovaBold.ttf'
import ProximaNovaBoldItalic from '../assets/fonts/ProximaNovaBoldItalic.ttf'

const svceGreen = '#02B198';
const svceBlack = '#0a2333';
const svceWhite = '#FFF';
const svceDarkGreen = '#029984';
const svceDarkGrey = '#475467';

// Pulled out of Figma
const codGray = '#131313';
const turbine = svceGreen;
const gridGreen = '#70c165';
const gridGreenLight = '#F5FFF5';
const gridGreenDark = '#4e8746';
const teal = '#a3d9d6';
const orange = '#f68d37';
const darkBlue = '#003863';
const grey = '#a3a7a9';
const darkGrey = '#475467';
const cloudGrey = '#e4e4e4';

const nonSelfReferentialTheme = createTheme({
  palette: {
    primary: {
      main: svceGreen,
      light: svceWhite,
      dark: svceDarkGreen,
      contrastText: svceBlack,
    },
    secondary: {
      main: svceBlack,
      light: svceGreen,
      dark: svceDarkGreen,
      contrastText: svceWhite,
    },
    svce: {
      codGray,
      turbine,
      gridGreen,
      teal,
      orange,
      darkBlue,
      grey,
      darkGrey,
      cloudGrey,
    },
    gray: {
      200: '#F1F1F1',
      250: '#F5F5F5',
      300: '#e0e0e0',
      600: '#666666',
      900: '#011627',
    },
    green: {
      100: '#ECFDF3',
      400: '#02ca9d',
      600: '#029875',
      700: '#027a48',
    },
    blue: {
      500: '#4dace9',
    },
  },
  typography: {
    fontFamily: 'Proxima Nova, gotham, sans-serif',
    h1: {
      fontSize: '48px',
      lineHeight: '108.333%',
      fontWeight: 600,
    },
    h2: {
      fontSize: '20px',
      lineHeight: '30px',
      fontWeight: 600,
    },
    h6: {
      fontSize: '18px',
      lineHeight: '1.5em',
      fontWeight: 400,
      color: svceBlack,
    },
  },
  shape: {
    svceBorderRadius: '50px',
    resourceCardBorderRadius: '4px',
  },
});

export const theme = createTheme(nonSelfReferentialTheme, {
  palette: {
    svceGreen: nonSelfReferentialTheme.palette.augmentColor({
      color: {
        main: svceGreen,
        contrastText: svceWhite,
      },
      name: 'svceGreen',
    }),
    whiteButton: nonSelfReferentialTheme.palette.augmentColor({
      color: {
        main: svceWhite,
        light: lighten(svceGreen, 0.3),
        dark: lighten(svceGreen, 0.9),
        contrastText: svceGreen,
      },
      name: 'white',
    }),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Proxima Nova'), url(${ProximaNovaRegular}) format('truetype');
        }
        @font-face {
          font-family: 'Proxima Nova';
          font-style: italic;
          font-display: swap;
          font-weight: 400;
          src: local('Proxima Nova'), url(${ProximaNovaRegularItalic}) format('truetype');
        }
        @font-face {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Proxima Nova'), url(${ProximaNovaMedium}) format('truetype');
        }
        @font-face {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Proxima Nova'), url(${ProximaNovaSemibold}) format('truetype');
        }
        @font-face {
          font-family: 'Proxima Nova';
          font-style: italic;
          font-display: swap;
          font-weight: 600;
          src: local('Proxima Nova'), url(${ProximaNovaSemiboldItalic}) format('truetype');
        }
        @font-face {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Proxima Nova'), url(${ProximaNovaBold}) format('truetype');
        }
        @font-face {
          font-family: 'Proxima Nova';
          font-style: italic;
          font-display: swap;
          font-weight: 700;
          src: local('Proxima Nova'), url(${ProximaNovaBoldItalic}) format('truetype');
        }
      `,
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&:focus-visible': {
            outline: '1px solid',
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          fontWeight: 500,
          '&.Mui-selected': {
            color: nonSelfReferentialTheme.palette.common.white,
          },
          '&:focus-visible': {
            outline: `1px solid ${nonSelfReferentialTheme.palette.common.black}`,
            '&.Mui-selected': {
              outlineOffset: '1px',
            },
          },
        },
      },
    },
  },
});
