import { Box, Typography } from '@mui/material';
import Link from '../../../components/Link';

export default function CategoryLink({ link, name, icon }) {
  return (
    <Link href={link} aria-label={`See More ${name}`}>
      <Box
        display="flex"
        height="100%"
        flexDirection="column"
        alignItems="center"
        mr={{ xs: 0, md: 3 }}
        ml={{ xs: 0, md: 3 }}
        py={{ xs: 1.5, md: 0 }}
      >
        <Box>{icon}</Box>
        <Typography
          mt={0.75}
          color="gray.500"
          textAlign="center"
          textTransform="uppercase"
          variant="h4"
          fontWeight={500}
        >
          {name}
        </Typography>
      </Box>
    </Link>
  );
}
