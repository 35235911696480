import SvceButton from './SvceButton';
import { Box, Typography } from '@mui/material';
import { Link as InertiaLink } from '@inertiajs/react';
import { getDetailsUrl } from '../utils/urlFunctions';

export default function CondensedResourceCard({ resource, setIsDrawerOpen }) {
  const {
    id,
    name,
    short_description: shortDescription,
    resource_card_cta: resourceCardCta,
    visual_guide_url: visualGuideUrl,
  } = resource;

  const url = getDetailsUrl({ id, type: 'resource' });

  return (
    <Box
      sx={{
        borderRadius: '4px',
        border: '1px solid',
        borderColor: 'gray.300',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box>
        <Box component="img" src={visualGuideUrl} alt="" maxHeight={163} width="100%" />
      </Box>
      <Box p={2} flexGrow={1} display="flex" flexDirection="column">
        <Typography fontSize="1.25rem" fontWeight={600} lineHeight="30px" mb={1}>
          {name}
        </Typography>
        <Typography color="svce.darkGrey" mb={1} flexGrow={1}>
          {shortDescription}
        </Typography>
        <SvceButton
          component={InertiaLink}
          href={url}
          preserveState={true}
          preserveScroll={true}
          onClick={() => setIsDrawerOpen(true)}
        >
          {resourceCardCta || 'View'}
        </SvceButton>
      </Box>
    </Box>
  );
}
