import { Box, CircularProgress, Drawer } from '@mui/material';
import ProductDetails from './DetailsDrawer/ProductDetails';
import ResourceDetails from './DetailsDrawer/ResourceDetails';

function DetailsDrawer({
  isOpen,
  onClose,
  detailedItemType,
  detailedItem,
  clearDetailedItem,
}) {
  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor="right"
      PaperProps={{
        role: 'dialog',
        'aria-labelledby': detailedItemType === 'product' ? 'product-drawer-header' : 'resource-drawer-header',
      }}
      sx={{
        '& .MuiDrawer-paper': {
          width: { xs: '100%', md: 'auto' },
        },
      }}
    >
      <Box width={{ xs: '100%', md: 645 }} position="relative">
        {!detailedItem && (
          <Box
            width="100%"
            height="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={100} />
          </Box>
        )}

        {detailedItemType === 'product' && !!detailedItem && (
          <ProductDetails
            product={detailedItem}
            onDrawerClose={onClose}
          />
        )}

        {detailedItemType === 'resource' && !!detailedItem && (
          <ResourceDetails
            resource={detailedItem}
            onDrawerClose={onClose}
            clearDetailedItem={clearDetailedItem}
          />
        )}
      </Box>
    </Drawer>
  );
}

export default DetailsDrawer;

