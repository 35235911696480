import { Typography } from '@mui/material';
import { usePage } from '@inertiajs/react';
import ApplianceTab from './ApplianceTab';

export default function ElectricDryer() {
  const {
    props: { dryer_image: dryerImage },
  } = usePage();

  return (
    <ApplianceTab
      heading="Electric Dryer"
      img={dryerImage}
      imgAlt="Image of an electric dryer"
    >
      <Typography mb={2}>
        When thinking about home energy use, the dryer might not be the first appliance that comes
        to mind. However, the average American family washes about 300 loads of laundry annually,
        and the energy use quickly adds up. Switching to an electric dryer is smart - they're safer,
        more affordable, and easier to install than gas dryers.
      </Typography>
      <Typography>Learn more about the benefits of electric dryers with The Basics.</Typography>
    </ApplianceTab>
  );
}
