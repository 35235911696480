import { useState, useEffect } from 'react';
import { router, usePage } from '@inertiajs/react';

export default function useDetailsDrawer() {
  const {
    props: { detailed_item: detailedItem, detailed_item_type: detailedItemType },
  } = usePage();

  const [isOpen, _setIsOpen] = useState(!!detailedItem);
  const [workingDetailedItem, setWorkingDetailedItem] = useState(detailedItem);
  const detailedItemKey = `${detailedItemType}_${detailedItem?.id}`;

  useEffect(() => {
    setWorkingDetailedItem(detailedItem);
  }, [detailedItemKey]);

  const setIsOpen = (newIsOpen) => {
    _setIsOpen(newIsOpen);

    const pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.delete('detailed_item_id');
    params.delete('detailed_item_type');
    const url = `${pathname}?${params.toString()}`;

    if (!newIsOpen) {
      router.visit(url, {
        preserveState: true,
        preserveScroll: true,
      });
    }
  };

  return {
    isOpen,
    setIsOpen,
    detailedItemType,
    detailedItem: workingDetailedItem,
    clearDetailedItem: () => setWorkingDetailedItem(undefined),
  };
}
