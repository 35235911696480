import { Typography } from '@mui/material';
import { usePage } from '@inertiajs/react';
import ApplianceTab from './ApplianceTab';

export default function WaterHeater() {
  const {
    props: { water_heater_image: waterHeaterImage, judy_image: judyImage },
  } = usePage();

  return (
    <ApplianceTab
      heading="Heat Pump Water Heater"
      img={waterHeaterImage}
      imgAlt="Image of a heat pump water heater"
      testimonial="“The experience [with the heat pump water heater] was seamless and I really appreciate
              being able to set an exact temperature for the water heater as I live with a
              temperature sensitive young child.”"
      testimonialName="Judy, Campbell"
      testimonialImg={judyImage}
    >
      <Typography mb={2}>
        Also known as hybrid electric water heaters, heat pump water heaters are far more efficient
        than typical water heaters. They use less energy by moving heat from the surrounding air and
        transferring it to water in an enclosed tank.
      </Typography>
      <Typography mb={2}>
        It's designed to work with other services, like the Electric Home (E-ELEC) rate, and can
        save you money in the long run. Learn about The Basics of heat pump water heaters.{' '}
      </Typography>
      <Typography mb={2}>
        Before you install a new water heater, visit Home Upgrades to explore a range of incentives,
        from rebates to full-service support, to guide your project. Need an emergency replacement
        for a broken water heater? We can help.
      </Typography>
    </ApplianceTab>
  );
}
