import { Box, Grid, Typography } from '@mui/material';
import HeadingWithFlair from '../../../components/HeadingWithFlair';
import CategoryTabs from './CategoryTabs';
import ProductList from './ProductList';
import FilterRail from '../../../components/Filters/FilterRail';
import ScrollToTop from '../../../components/ScrollToTop';
import CompareFooter from '../../../components/Compare/CompareFooter';
import MobileFilters from '../../../components/Filters/MobileFilters';
import Link from '../../../components/Link';
import { LeftArrowIcon } from '../../../assets/icons/icons';

const MOCK_DATA = {
  filters_data: [
    {
      id: 'price',
      name: 'Product Price Range',
      type: 'slider',
      min: 201,
      max: 1837,
      value_min: 201,
      value_max: 1837,
      step: 50,
      useDollars: true,
      collapsible: false,
      aria_labels: ['Min price', 'Max price'],
    },
    {
      id: 'brands',
      name: 'Brands',
      type: 'checkbox',
      collapsible: false,
      items: [
        {
          id: 'samsung',
          name: 'Samsung',
          checked: false,
        },
        {
          id: 'lg',
          name: 'LG',
          checked: false,
        },
        {
          id: 'ge',
          name: 'GE',
          checked: false,
        },
        {
          id: 'whirlpool',
          name: 'Whirlpool',
          checked: false,
        },
        {
          id: 'ge_profile',
          name: 'GE Profile',
          checked: false,
        },
        {
          id: 'electrolux',
          name: 'Electrolux',
          checked: false,
        },
        {
          id: 'maytag',
          name: 'Maytag',
          checked: false,
        },
        {
          id: 'haier',
          name: 'Haier',
          checked: false,
        },
        {
          id: 'fisher_paykel',
          name: 'Fisher & Paykel',
          checked: false,
        },
        {
          id: 'amana',
          name: 'Amana',
          checked: false,
        },
        {
          id: 'insignia',
          name: 'Insignia™',
          checked: false,
        },
      ],
    },
    {
      id: 'energy_star_most_efficient',
      name: 'ENERGY STAR Most Efficient',
      type: 'checkbox',
      collapsible: true,
      items: [
        {
          id: 'yes',
          name: 'Yes',
          checked: false,
        },
      ],
    },
    {
      id: 'load_configuration',
      name: 'Load Configuration',
      type: 'checkbox',
      collapsible: true,
      items: [
        {
          id: 'front_load',
          name: 'Front Load',
          checked: false,
        },
        {
          id: 'top_load',
          name: 'Top Load',
          checked: false,
        },
      ],
    },
    {
      id: 'stackable',
      name: 'Stackable',
      type: 'checkbox',
      collapsible: true,
      items: [
        {
          id: 'yes',
          name: 'Yes',
          checked: false,
        },
        {
          id: 'no',
          name: 'No',
          checked: false,
        },
      ],
    },
    {
      id: 'capacity',
      name: 'Capacity',
      type: 'slider',
      min: 1,
      max: 6,
      value_min: 1,
      value_max: 6,
      step: 1,
      useDollars: false,
      collapsible: true,
      aria_labels: ['Min capacity', 'Max capacity'],
    },
    {
      id: 'color',
      name: 'Color',
      type: 'checkbox',
      collapsible: true,
      items: [
        {
          id: 'white',
          name: 'White',
          checked: false,
        },
        {
          id: 'black',
          name: 'Black',
          checked: false,
        },
        {
          id: 'gray',
          name: 'Gray',
          checked: false,
        },
        {
          id: 'silver',
          name: 'Silver',
          checked: false,
        },
        {
          id: 'gold',
          name: 'Gold',
          checked: false,
        },
        {
          id: 'tan',
          name: 'Tan',
          checked: false,
        },
        {
          id: 'brown',
          name: 'Brown',
          checked: false,
        },
        {
          id: 'blue',
          name: 'Blue',
          checked: false,
        },
      ],
    },
    {
      id: 'app-controlled',
      name: 'App-Controlled',
      type: 'checkbox',
      collapsible: true,
      items: [
        {
          id: 'yes',
          name: 'Yes',
          checked: false,
        },
        {
          id: 'no',
          name: 'No',
          checked: false,
        },
      ],
    },
    {
      id: 'detergent_form',
      name: 'Detergent Form',
      type: 'checkbox',
      collapsible: true,
      items: [
        {
          id: 'liquid',
          name: 'Liquid',
          checked: false,
        },
        {
          id: 'powder',
          name: 'Powder',
          checked: false,
        },
        {
          id: 'single-use_packs',
          name: 'Single-use packs',
          checked: false,
        },
        {
          id: 'gel',
          name: 'Gel',
          checked: false,
        },
        {
          id: 'tablet',
          name: 'Tablet',
          checked: false,
        },
      ],
    },
    {
      id: 'number_of_wash_rinse_cycles',
      name: 'Number of Wash/Rinse Cycles',
      type: 'slider',
      min: 5,
      max: 37,
      value_min: 5,
      value_max: 37,
      step: 1,
      useDollars: false,
      collapsible: true,
      aria_labels: ['Min number_of_wash_rinse_cycles', 'Max number_of_wash_rinse_cycles'],
    },
    {
      id: 'steam_function',
      name: 'Steam Function',
      type: 'checkbox',
      collapsible: true,
      items: [
        {
          id: 'yes',
          name: 'Yes',
          checked: false,
        },
        {
          id: 'no',
          name: 'No',
          checked: false,
        },
      ],
    },
    {
      id: 'width',
      name: 'Width',
      type: 'slider',
      min: 23,
      max: 30,
      value_min: 23,
      value_max: 30,
      step: 1,
      useDollars: false,
      collapsible: true,
      aria_labels: ['Min width', 'Max width'],
    },
    {
      id: 'depth',
      name: 'Depth',
      type: 'slider',
      min: 22,
      max: 34,
      value_min: 22,
      value_max: 34,
      step: 1,
      useDollars: false,
      collapsible: true,
      aria_labels: ['Min depth', 'Max depth'],
    },
    {
      id: 'height',
      name: 'Height',
      type: 'slider',
      min: 33,
      max: 48,
      value_min: 33,
      value_max: 48,
      step: 1,
      useDollars: false,
      collapsible: true,
      aria_labels: ['Min height', 'Max height'],
    },
    {
      id: 'weight',
      name: 'Weight',
      type: 'slider',
      min: 127,
      max: 304,
      value_min: 127,
      value_max: 304,
      step: 10,
      useDollars: false,
      collapsible: true,
      aria_labels: ['Min weight', 'Max weight'],
    },
    {
      id: 'delay_start',
      name: 'Delay Start',
      type: 'checkbox',
      collapsible: true,
      items: [
        {
          id: 'yes',
          name: 'Yes',
          checked: false,
        },
        {
          id: 'no',
          name: 'No',
          checked: false,
        },
      ],
    },
  ],
};

export default function ProductsIndex({
  category,
  categories,
  products,
  sortType,
  sortOptions,
  pagination,
  countLabel,
  filters_data: filtersData = MOCK_DATA.filters_data,
}) {
  return (
    <Box>
      {/* mobile top bar */}
      <Box
        display={{ xs: 'flex', md: 'none' }}
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="gray.200"
        py={2}
        px={3}
      >
        <Link
          href="/product-categories"
          sx={{
            textDecoration: 'none',
            color: 'common.black',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box mr={1}>
            <LeftArrowIcon />
          </Box>
          <Typography
            variant="h4"
            component="p"
            fontWeight={700}
            fontSize="0.875rem"
            textTransform="uppercase"
          >
            Back
          </Typography>
        </Link>

        <MobileFilters filtersData={filtersData} variant="static" />
      </Box>

      <Box sx={{ p: { xs: 3, sm: 7 }, pb: { xs: 3, sm: 5 } }}>
        <Box display={{ xs: 'none', sm: 'block' }}>
          <HeadingWithFlair>Appliance Marketplace</HeadingWithFlair>
          <Typography fontSize="1.5rem" fontWeight={600}>
            Find the best products for you and your family
          </Typography>
          <Box width="100%" mt={4} mb={2}>
            <CategoryTabs selectedCategory={category} categories={categories} />
          </Box>
        </Box>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} md={4} xl={3} display={{ xs: 'none', md: 'flex' }}>
            <FilterRail
              title="Top Energy Products"
              description={(
                <>
                  <Typography mb={1}>
                    Shop only the most energy efficient products in your price range.
                  </Typography>
                  <Typography fontWeight={600} color="primary.main">
                    All products are{' '}
                    <Typography component="span" color="blue.500" fontWeight={600}>
                      ENERGY STAR®
                    </Typography>{' '}
                    certified where applicable.
                  </Typography>
                </>
              )}
              filtersData={filtersData}
            />
          </Grid>

          <Grid item xs={12} md={8} xl={9}>
            <ProductList
              category={category}
              countLabel={countLabel}
              products={products}
              sortType={sortType}
              sortOptions={sortOptions}
              pagination={pagination}
            />
          </Grid>
        </Grid>
      </Box>

      <ScrollToTop />

      <CompareFooter />
    </Box>
  );
}
