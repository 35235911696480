import { Box, Button, Typography } from '@mui/material';
import { CloseIcon, InfoIcon } from '../../../assets/icons/icons';
import { usePage } from '@inertiajs/react';
import useLocalStorage from '../../../hooks/useLocalStorage';

export default function WelcomeBanner() {
  const { component } = usePage();

  const [hasDismissedWelcomeBanner, setHasDismissedWelcomeBanner] =
    useLocalStorage('dismissedWelcomeBanner');

  // only show to users on landing page who haven't already dismissed banner
  if (hasDismissedWelcomeBanner || component !== 'Landing/Index') return null;

  return (
    <Box display="flex" p={2} mb={3} bgcolor="common.white" sx={{ borderRadius: '12px' }}>
      <Box flexShrink={0} mr={1.5}>
        <InfoIcon />
      </Box>
      <Box>
        <Typography fontWeight={600}>Welcome to the new eHub!</Typography>
        <Typography>
          We've added new and improved design and features to help you go electric.
        </Typography>
      </Box>
      <Box ml="auto">
        <Button
          aria-label="Close welcome banner"
          onClick={() => setHasDismissedWelcomeBanner('true')}
          sx={{ p: 0, ml: 2, minWidth: 'initial' }}
        >
          <CloseIcon />
        </Button>
      </Box>
    </Box>
  );
}
