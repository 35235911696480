import { Box, Grid, Typography } from '@mui/material';

export default function ProductDetailsFeatures({ product }) {
  if (!product.features.length) {
    return null;
  }

  return (
    <Box mb={{ xs: 4, sm: 0 }}>
      <Typography variant="h4" component="h3" fontSize="1.125rem" fontWeight="bold" mb={2.5}>
        Product Features
      </Typography>
      <Grid container component="dl" m={0} p={0}>
        {product.features.map(({ label, value }) => (
          <Grid key={label} item container xs={12} columnSpacing={2}>
            <Grid item xs={6} mb={1.5} component="dt">
              <Typography fontWeight={600}>{label}</Typography>
            </Grid>
            <Grid item xs={6} mb={1.5} component="dd">
              <Typography>{value}</Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
