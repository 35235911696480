// addQueryParam('/browse', 'category', 'ovens') -> '/browse?category=ovens'
export const addQueryParam = (url, param, value) => {
  const [baseUrl, params] = url.split('?');
  const urlSearchParams = new URLSearchParams(params);
  urlSearchParams.append(param, value);
  return baseUrl + `?${urlSearchParams.toString()}`;
};

// removeQueryParam('/browse?category=ovens', 'category') -> '/browse'
export const removeQueryParam = (url, param) => {
  const [baseUrl, params] = url.split('?');
  const urlSearchParams = new URLSearchParams(params);
  urlSearchParams.delete(param);
  return baseUrl + (urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : '');
};

// replaceQueryParam('/browse?category=ovens', 'category', 'thermostats') -> '/browse?category=thermostats'
export const replaceQueryParam = (url, param, value) => {
  const [baseUrl, params] = url.split('?');
  const urlSearchParams = new URLSearchParams(params);
  urlSearchParams.set(param, value);
  return baseUrl + `?${urlSearchParams.toString()}`;
};

export const getQueryParam = (url, param) => {
  const params = url.split('?')[1];
  const urlSearchParams = new URLSearchParams(params);
  return urlSearchParams.get(param);
};

export const getDetailsUrl = ({ id, type }) => {
  const pathname = window.location.pathname;
  const params = new URLSearchParams(window.location.search);
  params.set('detailed_item_id', id);
  params.set('detailed_item_type', type);
  return `${pathname}?${params.toString()}`;
};
