import { Box, Typography } from '@mui/material';
import times from 'lodash/times';
import VisuallyHiddenText from './VisuallyHiddenText';

export default function PriceRankDollars({ priceRank, variant = 'h5' }) {
  return (
    <Box>
      <Box aria-hidden="true" display="flex">
        {times(4, (idx) => (
          <Typography
            key={idx}
            component="span"
            fontWeight={priceRank - 1 >= idx ? 'bold' : 'normal'}
            fontSize="1.125rem"
            color={priceRank - 1 >= idx ? 'common.black' : 'gray.600'}
            variant={variant}
          >
            $
          </Typography>
        ))}
      </Box>
      <VisuallyHiddenText>{`${priceRank} out of 4 dollar signs`}</VisuallyHiddenText>
    </Box>
  );
}
