import { Box, Typography } from '@mui/material';
import { StarIconFilled } from '../../assets/icons/icons';
import energyStarMostEfficient from '../../assets/images/energy-star-most-efficient.png';
import { formatAsThousands } from '../../utils/formatters';
import PriceRankDollars from '../PriceRankDollars';

export default function ProductDetailsAccolades({ product }) {
  const { priceRankNum, priceRankText, reviewAverage, reviewCount, isEnergyStarMostEfficient } =
    product;

  return (
    <Box
      py={1.5}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderTop="1px solid"
      borderBottom="1px solid"
      borderColor="gray.300"
      flexWrap="wrap"
    >
      <Box display="flex" alignItems="center">
        <PriceRankDollars priceRank={priceRankNum} />
        <Typography variant="h4" fontSize="1.125rem" fontWeight={600} component="span" ml={0.5}>
          {priceRankText} price
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography fontSize="1.125rem" fontWeight={600}>
          {reviewAverage || 0}/5
        </Typography>
        <StarIconFilled aria-label="stars" />

        <Typography ml={0.5} fontSize="1.125rem" fontWeight={600}>
          ({formatAsThousands(reviewCount)} {reviewCount === 1 ? 'review' : 'reviews'})
        </Typography>
      </Box>
      {isEnergyStarMostEfficient && (
        <Box
          component="img"
          mt={2}
          src={energyStarMostEfficient}
          alt="Energy Star Most Efficient 2022"
          height={40}
        />
      )}
    </Box>
  );
}
