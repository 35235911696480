import { Link as InertiaLink, usePage } from '@inertiajs/react';
import { Box, Typography } from '@mui/material';
import { getQueryParam } from '../../utils/urlFunctions';

export default function NoResults({ handleLinkClick }) {
  const { url } = usePage();
  const searchQuery = getQueryParam(url, 'search');

  return (
    <Box mb={3}>
      <Typography variant="h3" fontSize="1.125rem" mb={1}>
        No results found for &quot;{searchQuery}&quot;
      </Typography>
      <Typography variant="h5" fontSize="1rem">
        Please try a more general term or browse by{' '}
        <InertiaLink href="/products" onClick={handleLinkClick}>
          products
        </InertiaLink>{' '}
        or{' '}
        <InertiaLink href="/recommendations" onClick={handleLinkClick}>
          resources
        </InertiaLink>
        .
      </Typography>
    </Box>
  );
}
