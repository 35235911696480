import { Box, Button, Typography, useTheme } from '@mui/material';
import React from 'react';
import { CircleCloseIcon } from '../../assets/icons/icons';

export default function CompareFooterProduct({ product, onDelete }) {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" mr={4}>
      <Box height={76} width={94} border="1px solid" borderColor="gray.300" position="relative">
        <Box py="10px" px="18px" height="100%" width="100%">
          <img
            src={product.imageUrl}
            alt={product.name}
            height="100%"
            width="auto"
            style={{ maxWidth: '100%', objectFit: 'contain' }}
          />
        </Box>
        <Button
          sx={{
            minWidth: 'auto',
            position: 'absolute',
            backgroundColor: 'common.white',
            top: -10,
            left: -10,
            p: 0,
          }}
          aria-label={`Remove comparison for ${product.name}`}
          onClick={onDelete}
        >
          <CircleCloseIcon color={theme.palette.primary.main} />
        </Button>
      </Box>
      <Box ml={1.5}>
        <Typography fontWeight={700}>{product.manufacturer}</Typography>
        <Typography fontSize="0.75rem" fontWeight={500}>
          {product.modelNumber}
        </Typography>
      </Box>
    </Box>
  );
}
