import { usePage, router } from '@inertiajs/react';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { CloseIcon } from '../../assets/icons/icons';
import ButtonLink from '../ButtonLink';
import CompareFooterProduct from './CompareFooterProduct';
import MobileCompareFooter from './MobileCompareFooter';
import VisuallyHiddenText from '../VisuallyHiddenText';
import useAriaLiveStatus from '../../hooks/useAriaLiveStatus';

const MOCK_COMPARE = [
  {
    id: 7,
    categoryId: 1,
    name: 'Google - Nest Learning Smart Wifi Thermostat - White',
    manufacturer: 'Google',
    modelNumber: 'T3017US',
    reviewCount: 27914,
    reviewAverage: 4.8,
    priceRankNum: 4,
    priceRankText: 'High',
    badges: [],
    isRebateEligible: true,
    rebate: '$75',
    regularPrice: '$249',
    salePrice: '$149',
    imageUrl:
      'https://pisces.bbystatic.com/prescaled/500/500/image2/BestBuy_US/images/products/5852/5852329_sd.jpg',
  },
  {
    id: 8,
    categoryId: 1,
    name: 'Google - Nest Learning Smart Wifi Thermostat - Copper',
    manufacturer: 'Google',
    modelNumber: 'T3021US',
    reviewCount: 36568,
    reviewAverage: 4.8,
    priceRankNum: 4,
    priceRankText: 'High',
    badges: ['Best Reviews'],
    isRebateEligible: true,
    rebate: '$75',
    regularPrice: '$249',
    salePrice: '$200',
    imageUrl:
      'https://pisces.bbystatic.com/prescaled/500/500/image2/BestBuy_US/images/products/5852/5852331_sd.jpg',
  },
  {
    id: 10,
    categoryId: 1,
    name: 'Google - Nest Learning Smart Wifi Thermostat - Black',
    manufacturer: 'Google',
    modelNumber: 'T3018US',
    reviewCount: 28086,
    reviewAverage: 4.8,
    priceRankNum: 3,
    priceRankText: 'Above Average',
    badges: [],
    isRebateEligible: true,
    rebate: '$75',
    regularPrice: '$180',
    salePrice: '$148',
    imageUrl:
      'https://pisces.bbystatic.com/prescaled/500/500/image2/BestBuy_US/images/products/6345/6345162_sd.jpg',
  },
];

export default function CompareFooter() {
  const {
    props: { compare_products: compareProducts = [] },
  } = usePage();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [ariaLiveStatus, setAriaLiveStatus] = useAriaLiveStatus();

  const handleDelete = (id) => {
    router.delete(`/compared_products/${id}`, {
      preserveScroll: true,
      preserveState: true,
      onSuccess: () => setAriaLiveStatus(`Removed product from compare`),
    });
  };

  const handleClose = () => {
    router.delete('/compare', {
      preserveScroll: true,
      preserveState: true,
    });
  };

  const compareLink =
    '/compare?' + compareProducts.map(({ id }) => `product_ids[]=${id}`).join('&');

  if (compareProducts.length === 0) return null;

  if (isSmallScreen)
    return (
      <MobileCompareFooter
        products={compareProducts}
        onDeleteProduct={handleDelete}
        onReset={handleClose}
        compareLink={compareLink}
      />
    );

  return (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      width="100%"
      bgcolor="common.white"
      display="flex"
      justifyContent="space-between"
      boxShadow={10}
      py={2}
      px={{ xs: 2, md: 4, lg: 6 }}
    >
      <Button
        onClick={handleClose}
        aria-label="Close comparison menu"
        sx={{
          minWidth: 'auto',
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <CloseIcon />
      </Button>
      <Typography fontWeight={600} display="flex" alignItems="center" mr={3}>
        Compare up to 3 products
      </Typography>
      <Box display="flex">
        <VisuallyHiddenText aria-live="polite" role="status">
          {ariaLiveStatus}
        </VisuallyHiddenText>
        {compareProducts.map((product) => (
          <CompareFooterProduct
            key={product.id}
            product={product}
            onDelete={() => handleDelete(product.id)}
          />
        ))}
      </Box>
      <ButtonLink
        href={compareLink}
        sx={{
          alignSelf: 'center',
          px: { xs: 2, lg: 4 },
          mr: { xs: 2, lg: 4 },
          '&:hover': { backgroundColor: 'green.400' },
        }}
        disabled={compareProducts.length < 2}
        aria-label="Compare selected products"
      >
        Compare
      </ButtonLink>
    </Box>
  );
}
