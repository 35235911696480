import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { useEffect, useRef } from 'react';
import PlaceholderImage from '../../assets/images/placeholder.jpg';
import { CloseIconHighContrast } from '../../assets/icons/icons';
import SvceButton from '../SvceButton';
import ApplicableProducts from './ApplicableProducts';
import Link from '../Link';

function MobileCtaLinks({ resource }) {
  const { learnMoreUrl, learnMoreCta, actionCta, actionUrl } = resource;

  const theme = useTheme();

  return (
    <Box
      display={{ xs: 'flex', sm: 'none' }}
      position="fixed"
      bottom={0}
      width="100%"
      backgroundColor="common.white"
      justifyContent="center"
      px={2}
      pb={2}
      py={3}
      boxShadow={theme.shadows[4]}
    >
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Box>
            <Button
              variant="secondaryInverted"
              external
              href={learnMoreUrl}
              fullWidth
              sx={{ py: 1.25 }}
            >
              {learnMoreCta}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Button variant="primary" external href={actionUrl} fullWidth sx={{ py: 1.25 }}>
            {actionCta}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default function ResourceDetails({ resource = {}, onDrawerClose, clearDetailedItem }) {
  const closeButtonRef = useRef(null);

  // send focus to the close button when the drawer opens
  useEffect(() => {
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, []);

  const {
    name,
    long_description: longDescription = 'A long description',
    info_urls: infoUrls = [],
    visual_guide_url: visualGuideUrl,
    learn_more_url: learnMoreUrl,
    learn_more_cta: learnMoreCta,
    action_url: actionUrl,
    action_cta: actionCta,
    applicable_products: applicableProducts = [],
  } = resource;

  return (
    <>
      <Box mb={{ xs: 14, sm: 0 }}>
        <Box
          sx={{
            height: { xs: 200, sm: 348 },
            display: 'flex',
            backgroundImage: `url(${PlaceholderImage})`,
            backgroundSize: 'cover',
          }}
        >
          <Button
            ref={closeButtonRef}
            onClick={onDrawerClose}
            sx={{ alignSelf: 'flex-start', ml: 'auto', zIndex: 100 }}
            aria-label="Close Resource Details"
          >
            <CloseIconHighContrast />
          </Button>
        </Box>
        <Box p={{ xs: 2, sm: 5.5 }} pt={{ xs: 4, sm: 5.5 }}>
          <Box>
            <Typography
              id="resource-drawer-header"
              variant="h2"
              fontWeight="bold"
              mb={{ xs: 2, sm: 2.5 }}
            >
              {name}
            </Typography>
            <Typography
              component="p"
              mb={{ xs: 0, sm: 2.5 }}
              color="gray.500"
              sx={{ whiteSpace: 'pre-line' }}
            >
              {longDescription}
            </Typography>
          </Box>
          <Box display={{ xs: 'none', sm: 'block' }}>
            <Box>
              {infoUrls.map(({ url, cta }) => (
                <Link key={url} external href={url} sx={{ mb: 2 }}>
                  {cta}
                </Link>
              ))}

              {visualGuideUrl && (
                <Box mt={4} mb={5}>
                  <EmbeddedVideo videoUrl={visualGuideUrl} title={name} width="567" height="285" />
                </Box>
              )}

              <Grid container spacing={3} mt={visualGuideUrl ? 0 : 5}>
                <Grid item xs={6}>
                  <SvceButton
                    variant="outlined"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={learnMoreUrl}
                    fullWidth
                    aria-label={`${learnMoreCta} about ${name}`}
                  >
                    {learnMoreCta}
                  </SvceButton>
                </Grid>
                <Grid item xs={6}>
                  <SvceButton
                    href={actionUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    fullWidth
                    aria-label={`${name} - ${actionCta}`}
                  >
                    {actionCta}
                  </SvceButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        {applicableProducts && !!applicableProducts.length && (
          <Box p={{ xs: 2, sm: 5.5 }} backgroundColor="gray.100">
            <ApplicableProducts resource={resource} clearDetailedItem={clearDetailedItem} />
          </Box>
        )}
      </Box>

      <MobileCtaLinks resource={resource} />
    </>
  );
}
