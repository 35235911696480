import { Typography } from '@mui/material';
import { usePage } from '@inertiajs/react';
import ApplianceTab from './ApplianceTab';

export default function YardCare() {
  const {
    props: { leaf_blower_image: leafBlowerImage },
  } = usePage();

  return (
    <ApplianceTab
      heading="Yard Care Equipment"
      img={leafBlowerImage}
      imgAlt="Image of an electric leaf blower"
    >
      <Typography mb={2}>
        Don't settle for outdated, polluting yard equipment. Upgrade to electric and enjoy a
        cleaner, quieter, and easier experience. Electric leaf blowers, lawn mowers, trimmers, and
        chainsaws provide the same power as gas models, but with zero emissions, minimal
        maintenance, and much less noise.
      </Typography>
      <Typography>
        Gas-powered yard equipment causes significant air pollution. Discover the health impacts
        with some surprising facts from the California Air Resources Board.
      </Typography>
    </ApplianceTab>
  );
}
