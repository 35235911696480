import { Typography } from '@mui/material';

export default function VisuallyHiddenText({ children, ...props }) {
  return (
    <Typography
      component="span"
      sx={{
        position: 'absolute !important',
        height: '1px',
        width: '1px',
        overflow: 'hidden',
        padding: '0 !important',
        border: '0! important',
        whiteSpace: 'nowrap !important',
        clip: 'rect(1px, 1px, 1px, 1px) !important',
        clipPath: 'inset(50%) !important',
      }}
      {...props}
    >
      {children}
    </Typography>
  );
}
