import React, { useEffect, useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Box, useTheme } from '@mui/material';
import Link from '../../components/Link';
import SearchBar from '../../components/SearchBar';
import SearchModal from '../../components/SearchModal/SearchModal';
import { SearchIcon } from '../../assets/icons/icons';

const SVCELogoHotlink = 'https://svcleanenergy.org/wp-content/uploads/2018/04/SVC_logo.png';
const SVCEDownCaret = 'https://svcleanenergy.org/wp-content/uploads/down-caret3-01.png';

const HeaderMenu = ({ title, items }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls={open ? 'basic-menu' : void 0}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : void 0}
        onClick={handleClick}
        color="secondary"
        sx={{
          '&:after': {
            content: `url(${SVCEDownCaret})`,
            backgroundRepeat: 'no-repeat, repeat',
            paddingLeft: '5px',
          },
        }}
      >
        {title}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        {items.map((item) => (
          <MenuItem key={item} onClick={handleClose}>
            <Typography textAlign="left">{item}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const HeaderLink = ({ text, href }) => {
  const theme = useTheme();
  return (
    <Link href={href} external>
      <Typography
        mt="10px"
        mb="10px"
        sx={{
          fontSize: '13px',
          lineHeight: '20.3px',
          color: theme.palette.common.white,
        }}
      >
        {text}
      </Typography>
    </Link>
  );
};

export const Header = () => {
  const theme = useTheme();

  const headerRef = useRef(null);
  const modalRef = useRef(null);
  const searchBarRef = useRef(null);
  const searchButtonRef = useRef(null);

  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  // close search modal and mobile nav menu on outside click
  useEffect(() => {
    const closeModalOnOutsideClick = (e) => {
      const isModalClick = modalRef.current && modalRef.current.contains(e.target);
      const isSearchBarClick = searchBarRef.current && searchBarRef.current.contains(e.target);
      const isSearchButtonClick =
        searchButtonRef.current && searchButtonRef.current.contains(e.target);

      if (!isModalClick && !isSearchBarClick && !isSearchButtonClick) {
        setIsSearchModalOpen(false);
      }
    };
    document.addEventListener('click', closeModalOnOutsideClick);
    return () => {
      document.removeEventListener('click', closeModalOnOutsideClick);
    };
  }, []);

  return (
    <Stack>
      <Stack direction="row" alignItems="center" spacing={2} justifyContent="flex-end" mr={2}>
        <HeaderLink href="https://svcleanenergy.org/plans-policies-reports/" text="Key Documents" />
        <HeaderLink href="https://svcleanenergy.org/meetings/" text="Meetings & Agendas" />
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        pt={2}
        pb={2}
        sx={{
          backgroundColor: theme.palette.common.white,
        }}
      >
        <Link href="/">
          <img src={SVCELogoHotlink} />
        </Link>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ display: { xs: 'none', md: 'flex' } }}
        >
          <HeaderMenu
            title="About Us"
            items={[
              'Our Story',
              'Who We Are',
              'Service Area',
              'How It Works',
              'Renewable Projects',
            ]}
          />
          <HeaderMenu
            title="Go Electric - Rebates & Offers"
            items={['Get Started with eHub', 'Rebates & Incentives', 'Find A Contractor']}
          />
          <HeaderMenu
            title="Rates & Billing"
            items={[
              'Service Plans',
              'Electricity Rates',
              'Understanding Your Bill',
              'Payment Assistance',
              'Terms & Conditions',
            ]}
          />
        </Stack>

        {/* small screen search button */}
        <Button
          ref={searchButtonRef}
          data-testid="mobile-search-button"
          aria-label="Search products and resources"
          aria-expanded={isSearchModalOpen ? 'true' : 'false'}
          onClick={() => setIsSearchModalOpen((prev) => !prev)}
          sx={{
            display: { xs: 'flex', md: 'none' },
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '100%',
            p: 1,
            m: 0,
          }}
        >
          <SearchIcon />
        </Button>

        <Box display={{ xs: 'none', md: 'block' }}>
          <SearchBar ref={searchBarRef} onFocus={() => setIsSearchModalOpen(true)} />
        </Box>

        <SearchModal
          ref={modalRef}
          isOpen={isSearchModalOpen}
          onClose={() => setIsSearchModalOpen(false)}
        />
      </Stack>
    </Stack>
  );
};
