import { Box, Grid, useTheme } from '@mui/material';
import SvceButton from '../SvceButton';

export default function MobileFiltersButtons({ handleReset, handleUpdate }) {
  const theme = useTheme();

  return (
    <Box
      display={{ xs: 'flex', md: 'none' }}
      position="fixed"
      bottom={0}
      left={0}
      width="100%"
      px={2}
      py={3}
      backgroundColor="common.white"
      boxShadow={theme.shadows[4]}
    >
      <Grid container columnSpacing={2.5}>
        <Grid item xs={6}>
          <SvceButton
            sx={{
              width: '100%',
              backgroundColor: 'common.white',
              border: '1px solid',
              borderColor: 'primary.main',
              color: 'primary.main',
              borderRadius: '8px',
              py: 1,
              fontWeight: 'bold',
              '&:hover, &:focus': {
                backgroundColor: 'gray.100',
              },
            }}
            onClick={handleReset}
          >
            Reset
          </SvceButton>
        </Grid>
        <Grid item xs={6}>
          <SvceButton
            sx={{
              width: '100%',
              backgroundColor: 'primary.main',
              color: 'common.white',
              borderRadius: '8px',
              border: '1px solid',
              borderColor: 'primary.main',
              py: 1,
              fontWeight: 'bold',
              '&:hover, &:focus': {
                backgroundColor: 'green.600',
                borderColor: 'green.600',
              },
            }}
            onClick={handleUpdate}
          >
            Update
          </SvceButton>
        </Grid>
      </Grid>
    </Box>
  );
}
