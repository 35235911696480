import { Box, Grid, Typography } from '@mui/material';
import ProductCard from '../ProductCard';
import useDetailsDrawer from '../../hooks/useDetailsDrawer';

export default function ProductDetailsSimilarProducts({ product, wrapperRef }) {
  const { similarProducts: similarProducts } = product;

  const { setIsOpen: setIsDrawerOpen } = useDetailsDrawer();

  return (
    <Box>
      <Typography variant="h4" component="h3" fontWeight="bold" fontSize="1.125rem" mb={2.5}>
        Similar products
      </Typography>
      <Grid container spacing={1}>
        {similarProducts.map((similarProduct) => (
          <Grid item xs={12} sm={6} key={similarProduct.id}>
            <ProductCard
              product={similarProduct}
              setIsDrawerOpen={setIsDrawerOpen}
              onFetchSuccess={() => wrapperRef.current.scrollIntoView()}
              disableCompare
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
