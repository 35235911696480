import { Box, Typography, Button, useTheme } from '@mui/material';
import { Link as InertiaLink, router } from '@inertiajs/react';
import ProductBadge from './ProductBadge';
import { PriceTagIcon, StarIconFilled } from '../assets/icons/icons';
import VisuallyHiddenText from './VisuallyHiddenText';
import { formatAsThousands } from '../utils/formatters';
import ProductCompareButton from './Compare/ProductCompareButton';
import { Inertia } from '@inertiajs/inertia';
import { getDetailsUrl } from '../utils/urlFunctions';

export default function ProductCard({
  product,
  setIsDrawerOpen = () => {},
  onFetchSuccess = () => {},
  disableCompare,
  onClick = () => {},
}) {
  const {
    id,
    name,
    imageUrl,
    badges,
    salePrice,
    regularPrice,
    reviewAverage,
    reviewCount,
    isRebateEligible,
    rebate,
  } = product;

  const theme = useTheme();

  const url = getDetailsUrl({ id, type: 'product' });

  const handleClick = () => {
    onClick();
    setIsDrawerOpen(true);
  };

  return (
    <Box
      position="relative"
      sx={{
        display: 'block',
        cursor: 'pointer',
        color: 'inherit',
        textAlign: 'initial',
        textTransform: 'none',
        textDecoration: 'none',
        height: '100%',
        width: '100%',
        border: '1px solid',
        borderColor: 'gray.300',
        borderRadius: '4px',
        outlineOffset: -1,
      }}
    >
      {!disableCompare && <ProductCompareButton product={product} />}
      <Box
        as={InertiaLink}
        display="flex"
        flexDirection="column"
        backgroundColor="common.white"
        width="100%"
        height="100%"
        href={url}
        onClick={handleClick}
        preserveScroll
        preserveState
        onSuccess={onFetchSuccess}
        sx={{
          cursor: 'pointer',
          transition: 'all 200ms ease',
          borderRadius: '4px',
          '&:hover, &:focus': {
            boxShadow: 3,
          },
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        <Box display="flex" justifyContent="center" mb={2} px={2} pb={0} pt={4}>
          <Box
            component="img"
            src={imageUrl}
            alt=""
            height={115}
            width="auto"
            textAlign="center"
            overflow="hidden"
          />
        </Box>

        <Box
          p={2}
          borderTop="1px solid"
          borderColor="gray.300"
          display="flex"
          flexDirection="column"
          height="100%"
        >
          <Box display="flex" flexShrink={0} flexWrap="wrap" mb={1}>
            {!!badges.length &&
              badges.map((badge, idx) => (
                <Box
                  key={badge}
                  alignSelf="flex-start"
                  mr={idx === badges.length - 1 ? 0 : 0.5}
                  mb={idx === badges.length - 1 ? 0 : 0.5}
                >
                  <ProductBadge text={badge} />
                </Box>
              ))}
          </Box>

          <Typography component="h3" mb={1}>
            <Button
              sx={{
                flexDirection: 'column',
                textAlign: 'left',
                textTransform: 'none',
                alignItems: 'flex-start',
                color: 'common.black',
                p: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <Typography variant="h5" fontSize="1rem" component="span" fontWeight={600}>
                {name}
              </Typography>
            </Button>
          </Typography>

          <Box sx={{ flexGrow: 1 }} mb={2}>
            {reviewAverage && (
              <Typography variant="h6" fontSize="0.875rem" display="flex" alignItems="center">
                <StarIconFilled aria-label="stars" />{' '}
                <Typography component="span" fontWeight={700} ml={0.5}>
                  {reviewAverage}
                </Typography>
                <Typography component="span" fontWeight={400} ml={0.5}>
                  ({formatAsThousands(reviewCount)} {reviewCount === 1 ? 'review' : 'reviews'})
                </Typography>
              </Typography>
            )}
          </Box>

          <Box display="flex" alignItems="flex-end" justifyContent="space-between">
            <Box>
              {isRebateEligible && (
                <Box display="flex" alignItems="center">
                  <PriceTagIcon fill={theme.palette.svce.orange} />
                  <Typography
                    variant="h6"
                    fontSize="0.875rem"
                    component="span"
                    color="svce.orange"
                    fontWeight={600}
                    ml={0.5}
                  >
                    {!rebate && 'Rebate Eligible'}
                    {rebate && `${rebate} Rebate`}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              {!!regularPrice && regularPrice !== salePrice && (
                <>
                  <VisuallyHiddenText>Regular price:</VisuallyHiddenText>
                  <Typography
                    variant="h5"
                    fontSize="1rem"
                    component="del"
                    color="gray.600"
                    textAlign="right"
                  >
                    {regularPrice} full price
                  </Typography>
                </>
              )}
              <VisuallyHiddenText>Sale price:</VisuallyHiddenText>
              <Typography variant="h2" fontSize="2.25rem" component="span" fontWeight={600}>
                {salePrice}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
