import { Slider } from '@mui/material';
import CheckboxFilter from './CheckboxFilter';
import SliderFilter from './SliderFilter';
import CollapsibleFilter from './CollapsibleFilter';
import SelectFilter from './SelectFilter';
import NonNumericSliderFilter from './NonNumericSliderFilter';

const CollapsibleFilterWrapper = ({ collapsible, title, children }) =>
  collapsible ? <CollapsibleFilter title={title}>{children}</CollapsibleFilter> : children;

export default function Filter({
  filterData: {
    id,
    type,
    name,
    min,
    max,
    step,
    useDollars,
    value_min: valueMin,
    value_max: valueMax,
    aria_labels: ariaLabels,
    items,
    collapsible,
    options,
    value,
    marks,
  },
  onCheckboxChange,
  onSliderChange,
  onNonNumericSliderChange,
  onSelectChange,
  truncatable,
}) {

  return (
    <CollapsibleFilterWrapper collapsible={collapsible} title={name}>
      {type === 'slider' && (
        <SliderFilter
          title={collapsible ? '' : name}
          min={min}
          max={max}
          value={[valueMin, valueMax]}
          onChange={(value) => onSliderChange(id, value)}
          step={step}
          useDollars={useDollars}
          ariaLabels={ariaLabels}
        />
      )}

      {type === 'checkbox' && (
        <CheckboxFilter
          id={id}
          title={collapsible ? '' : name}
          items={items}
          onChange={onCheckboxChange}
          truncatable={truncatable}
        />
      )}

    {type === 'select' && (
      <SelectFilter
        id={id}
        name={name}
        options={options}
        onChange={onSelectChange}
        value={value}
      />
    )}

    {type === 'non_numeric_slider' && (
      <NonNumericSliderFilter
        id={id}
        title={collapsible ? '' : name}
        step={null}
        valueLabelDisplay="off"
        value={value}
        onChange={onNonNumericSliderChange}
        marks={marks}
      />
    )}

    </CollapsibleFilterWrapper>
  );
}
