import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';
import ButtonLink from '../../../components/ButtonLink';
import Link from '../../../components/Link';
import {
  CarIcon,
  FlashIcon,
  HeartIcon,
  HomeIcon,
  ShoppingCartIcon,
} from '../../../assets/icons/icons';

const QuickLink = ({ icon, text, link }) => (
  <Link sx={{ display: 'flex', alignItems: 'center' }} href={link} external={!link.startsWith('/')}>
    <Box flexShrink={0} mr={1.25} display="flex" alignItems="center">
      {icon}
    </Box>
    <Typography sx={{ textDecoration: 'underline', color: 'common.black', fontWeight: 600 }}>
      {text}
    </Typography>
  </Link>
);

const QUICK_LINKS = [
  { icon: <FlashIcon />, textKey: 'go_electric', link: 'https://goelectric.svcleanenergy.org/' },
  { icon: <HomeIcon />, textKey: 'electric_at_home', link: '/electric-at-home' },
  { icon: <CarIcon />, textKey: 'ev_finder', link: 'https://ev.svcleanenergy.org/' },
  { icon: <HeartIcon />, textKey: 'home_upgrades', link: '/home-upgrade' },
  { icon: <ShoppingCartIcon />, textKey: 'products', link: '/products' },
];

export default function Hero({ imagePath, copy }) {
  const theme = useTheme();
  return (
    <Grid container sx={{ borderTopRightRadius: theme.shape.svceBorderRadius }}>
      <Grid item xs={12} md={6}>
        <Box
          px={{ xs: 2, md: 4, xl: 8 }}
          pt={{ xs: 2, md: 4 }}
          mt={{ xs: 2, md: 4 }}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Box mt="auto">
            <Typography
              variant="h1"
              fontSize={{ xs: '2.5rem', md: '3.5rem', lg: '4.375rem' }}
              fontWeight={600}
              lineHeight={{ xs: '35px', md: '60px' }}
              mb={2}
              textAlign={{ xs: 'center', md: 'left' }}
            >
              {copy.title.lead}{' '}
              <Typography
                component="span"
                fontSize={{ xs: '2.5rem', md: '3.5rem', lg: '4.375rem' }}
                fontWeight={600}
                color="primary.main"
                fontStyle="italic"
                lineHeight={{ xs: '35px', md: '60px' }}
              >
                {copy.title.contrast}
              </Typography>
              .
            </Typography>

            <Typography
              fontSize={{ xs: '1rem', md: '1.125rem' }}
              textAlign={{ xs: 'center', md: 'left' }}
              fontWeight={600}
              mb={5}
            >
              {copy.subtitle}
            </Typography>

            <ButtonLink
              href="/recommendations"
              sx={{ display: 'inline-block', width: { xs: '100%', md: 'auto' } }}
            >
              {copy.recommendation_button}
            </ButtonLink>
          </Box>

          <Box p={3} mb={3} bgcolor="gray.250" mt={{ xs: 4, md: 'auto' }}>
            <Typography fontSize="1.125rem" fontWeight={600} mb={2.5}>
              {copy.links.title}
            </Typography>
            <Grid container spacing={3}>
              {QUICK_LINKS.map(({ icon, textKey, link }) => (
                <Grid item key={textKey}>
                  <QuickLink icon={icon} text={copy.links[textKey]} link={link} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box
          component="img"
          alt=""
          src={imagePath}
          sx={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            maxHeight: 780,
            borderTopRightRadius: theme.shape.svceBorderRadius,
          }}
          display={{ xs: 'none', md: 'inline-block' }}
        />
      </Grid>
    </Grid>
  );
}
