import { Typography } from '@mui/material';
import { usePage } from '@inertiajs/react';
import ApplianceTab from './ApplianceTab';

export default function HeatPump() {
  const {
    props: { heat_pump_image: heatPumpImage, curt_image: curtImage },
  } = usePage();

  return (
    <ApplianceTab
      heading="Heat Pump Heating & Cooling"
      img={heatPumpImage}
      imgAlt="Image of a heat pump"
      testimonial="“The new heating and air conditioning system is much more effective at regulating the temperature for the first floor and second floor of the house. It turns out our prior AC system had an undersized air return. The new system is also much quieter than the old system.”"
      testimonialName="Curt, Gilroy"
      testimonialImg={curtImage}
    >
      <Typography mb={2}>
        Heat pump space heating and cooling systems work by taking heat from surrounding areas and
        moving it to another location. In the summertime, it moves heat from inside your home to the
        outside and does the opposite in winter. Learn more about heat pump space heating and
        cooling with The Basics.
      </Typography>
      <Typography>
        Visit Home Upgrades to explore incentives, from rebates to full-service installation
        support, for heat pumps.
      </Typography>
    </ApplianceTab>
  );
}
