import { Box, Container, Typography, useTheme } from '@mui/material';
import HeadingWithFlair from '../../../components/HeadingWithFlair';
import Link from '../../../components/Link';
import { LeftArrowIcon } from '../../../assets/icons/icons';
import Faq from './Faq';
import HelpLinks from './HelpLinks';
import ButtonLink from '../../../components/ButtonLink';
import Appliances from './Appliances';

export default function ElectricAtHome({ hero_image: heroImage, back_url: backUrl }) {
  const theme = useTheme();

  return (
    <Box py={{ xs: 0, md: 3, lg: 6 }}>
      <Box display="flex" flexDirection="column">
        <Box order={{ xs: 2, md: 1 }}>
          <Container maxWidth="xl">
            <Link
              href={backUrl}
              sx={{
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                py: 3,
              }}
            >
              <Box mr={1}>
                <LeftArrowIcon fill={theme.palette.primary.main} />
              </Box>
              <Typography
                variant="h4"
                component="p"
                fontWeight={700}
                fontSize="0.875rem"
                textTransform="uppercase"
              >
                Back
              </Typography>
            </Link>
            <HeadingWithFlair>Electric at Home</HeadingWithFlair>
            <ButtonLink href="/products" sx={{ width: 230, mb: { xs: 3, md: 7 } }}>
              Appliances Marketplace
            </ButtonLink>
          </Container>
        </Box>

        <Box width="100%" order={{ xs: 1, md: 2 }}>
          <Box
            component="img"
            alt=""
            src={heroImage}
            sx={{
              objectFit: 'cover',
              objectPosition: 'center',
              width: '100%',
              height: '100%',
              maxHeight: { xs: 240, md: 545 },
              borderTopLeftRadius: { xs: 50, md: 0 },
              borderTopRightRadius: { xs: 50, md: 0 },
            }}
          />
        </Box>
      </Box>

      <Container maxWidth="md">
        <Box my={{ xs: 3, md: 6 }}>
          <Typography variant="h2" fontWeight={600} fontSize="2rem">
            Why home appliances matter.
          </Typography>
          <Typography fontSize={{ xs: '1rem', md: '1.25rem' }} mt={2}>
            Nearly a quarter of Silicon Valley's air pollution comes from fossil fuels used in homes
            for heating water, indoor air, dryers, and stoves. Burning fossil fuels, like methane
            gas and propane, harms air quality and drives climate change. Upgrading gas appliances
            and equipment to electric ones reduces pollution, improves energy efficiency, and
            enhances home safety and comfort. Discover the right electric solutions for your home
            and make a positive impact on the environment and a better future for our community.
          </Typography>
        </Box>
      </Container>

      <Appliances />

      <Container maxWidth="md">
        <Box my={{ xs: 3, md: 6 }}>
          <Typography variant="h2" fontWeight={600} fontSize="2rem">
            Discover how to go even further on your all-electric journey
          </Typography>
          <Typography fontSize={{ xs: '1rem', md: '1.25rem' }} mt={2}>
            If you're transitioning to electric technologies like heat pumps or an electric vehicle,
            installing solar+battery systems could be a logical choice. By aligning your
            energy-intensive appliances and EV charging with daytime solar production and using
            battery storage for evening use, you can optimize energy benefits, cut emissions, and
            potentially lower your electric bill.
          </Typography>
        </Box>
      </Container>

      <HelpLinks />

      <Faq />
    </Box>
  );
}
