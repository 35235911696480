import { Box, Pagination } from '@mui/material';
import { router, usePage } from '@inertiajs/react';
import ResourceCard from '../../../components/ResourceCard';
import useDetailsDrawer from '../../../hooks/useDetailsDrawer';
import DetailsDrawer from '../../../components/DetailsDrawer';

const DUMMY_RESOURCES = [
  {
    id: 1,
    header: "Resource 1",
    shortDescription: "This is a short description of Resource 1",
    resourceCardCta: "View",
  },
  {
    id: 2,
    header: "Resource 2",
    shortDescription: "This is a short description of Resource 2",
    resourceCardCta: "View",
  }
]
export default function ResourceList() {
  const { props: { resources, pagination } } = usePage();

  const { page, pages, scaffold_url: scaffoldUrl } = pagination;

  const handleNewPage = (page) => {
    const newPageUrl = scaffoldUrl.replace('__pagy_page__', page);
    router.visit(newPageUrl, {
      preserveScroll: false,
      preserveState: true,
    });
  };

  const {
    isOpen: isDrawerOpen,
    setIsOpen: setIsDrawerOpen,
    detailedItem,
    detailedItemType,
    clearDetailedItem,
  } = useDetailsDrawer();

  return (
    <>
      <Box display="flex" flexDirection="column">
        {resources.map((resource) => {
          const { id } = resource;
          return (
            <Box key={id} mb={2}>
              <ResourceCard
                resource={resource}
                setIsDrawerOpen={setIsDrawerOpen}
              />
            </Box>
          );
        })}

        <Box display="flex" justifyContent="center" mt={2}>
          <Pagination
            page={page}
            count={pages}
            onChange={(_, num) => handleNewPage(num)}
            color="primary"
          />
        </Box>
      </Box>

      <DetailsDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        detailedItemType={detailedItemType}
        detailedItem={detailedItem}
        clearDetailedItem={clearDetailedItem}
      />
    </>
  );
}
