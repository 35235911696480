import { Box, Button, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import MobileCompareDialog from './MobileCompareDialog';
import SvceButton from '../SvceButton';

export default function MobileCompareFooter({ products, onDeleteProduct, onReset, compareLink }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const theme = useTheme();

  return (
    <Box
      display={{ xs: 'flex', md: 'none' }}
      position="fixed"
      bottom={0}
      left={0}
      width="100%"
      backgroundColor="common.white"
      justifyContent="center"
      px={2}
      py={3}
      boxShadow={theme.shadows[4]}
    >
      <SvceButton
        onClick={() => setIsDialogOpen(true)}
        aria-label="View compared products"
        sx={{
          py: 1.25,
          borderRadius: '8px',
          width: '100%',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: 'green.400',
          },
        }}
      >
        <Typography fontSize="0.875rem" color="common.white" fontWeight="bold">
          View Compare
        </Typography>
        <Typography
          fontSize="0.75rem"
          sx={{
            position: 'absolute',
            top: '-12px',
            right: '-12px',
            height: '24px',
            width: '24px',
            border: '1px solid',
            borderColor: 'common.black',
            borderRadius: '50%',
            backgroundColor: 'common.white',
            color: 'common.black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {products.length}
        </Typography>
      </SvceButton>

      <MobileCompareDialog
        isOpen={isDialogOpen}
        products={products}
        onClose={() => setIsDialogOpen(false)}
        onDeleteProduct={onDeleteProduct}
        onReset={onReset}
        compareLink={compareLink}
      />
    </Box>
  );
}
