import { Checkbox as MuiCheckbox, styled } from '@mui/material';

const Icon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  border: `1px solid ${theme.palette.primary.main}`,
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.gray[100],
  },
}));

const CheckedIcon = styled(Icon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '&:before': {
    display: 'block',
    width: 17,
    height: 17,
    backgroundImage:
      'url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox=%22-2%20-2%2017%2017%22%20fill=%22none%22%20xmlns=%22http://www.w3.org/2000/svg%22%3E%0A%3Cpath%20fill-rule=%22evenodd%22%20clip-rule=%22evenodd%22%20d=%22M3.79464%205.92524L9.22437%200.195338C9.47118%20-0.0651125%209.86881%20-0.0651125%2010.1156%200.195338L10.8149%200.93328C11.0617%201.19373%2011.0617%201.61334%2010.8149%201.85932L4.24712%208.80466C4.00031%209.06511%203.60268%209.06511%203.35587%208.80466L0.174821%205.44775C-0.0582736%205.20177%20-0.0582736%204.78215%200.174821%204.5217L0.887816%203.78376C1.12091%203.52331%201.51854%203.52331%201.76535%203.78376L3.79464%205.92524Z%22%20fill=%22white%22/%3E%0A%3C/svg%3E%0A")',
    content: '""',
  },
}));

export default function Checkbox({ ariaLabel, ...props }) {
  return (
    <MuiCheckbox
      sx={{
        paddingRight: '0.75rem',
      }}
      disableRipple
      color="default"
      checkedIcon={<CheckedIcon />}
      icon={<Icon />}
      inputProps={{ 'aria-label': ariaLabel }}
      {...props}
    />
  );
}
