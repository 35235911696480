import { Box, Grid, Pagination } from '@mui/material';
import ProductCard from '../../../components/ProductCard';
import SortBar from './SortBar';
import useDetailsDrawer from '../../../hooks/useDetailsDrawer';
import DetailsDrawer from '../../../components/DetailsDrawer';
import { router } from '@inertiajs/react';
import NoResults from './NoResults';

export default function ProductList({
  products,
  category,
  sortType,
  sortOptions,
  pagination,
  countLabel,
}) {
  const {
    isOpen: isDrawerOpen,
    setIsOpen: setIsDrawerOpen,
    detailedItem,
    detailedItemType,
    clearDetailedItem,
  } = useDetailsDrawer();

  const { page, pages, scaffold_url: scaffoldUrl } = pagination;

  const handleNewPage = (page) => {
    const newPageUrl = scaffoldUrl.replace('__pagy_page__', page);
    router.visit(newPageUrl, {
      preserveScroll: false,
      preserveState: true,
    });
  };

  if (!products.length) return <NoResults />;

  return (
    <>
      <Box display="flex" flexDirection="column">
        <SortBar
          sortType={sortType}
          sortOptions={sortOptions}
          countLabel={countLabel}
          category={category}
        />

        <Grid container spacing={2}>
          {products.map((product) => (
            <Grid item key={product.id} xs={12} sm={6} lg={4} xl={3}>
              <ProductCard
                product={product}
                setIsDrawerOpen={setIsDrawerOpen}
              />
            </Grid>
          ))}
        </Grid>

        <Box display="flex" justifyContent="center" mt={2}>
          <Pagination
            page={page}
            count={pages}
            onChange={(_, num) => handleNewPage(num)}
            color="primary"
          />
        </Box>
      </Box>

      <DetailsDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        detailedItemType={detailedItemType}
        detailedItem={detailedItem}
        clearDetailedItem={clearDetailedItem}
      />
    </>
  );
}
