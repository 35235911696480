const MOCK_CATEGORY_GROUPS = [
  {
    id: 7,
    name: 'Major Appliances',
    categories: [
      {
        id: 5,
        handle: 'dishwashers',
        name: 'Dishwashers',
        link: '/products/dishwashers',
        hasRebates: false,
      },
      {
        id: 2,
        handle: 'dryers',
        name: 'Dryers',
        link: '/products/dryers',
        hasRebates: false,
      },
      {
        id: 6,
        handle: 'freezers',
        name: 'Freezers',
        link: '/products/freezers',
        hasRebates: false,
      },
      {
        id: 17,
        handle: 'induction',
        name: 'Induction Cooking',
        link: '/products/induction',
        hasRebates: false,
      },
      {
        id: 7,
        handle: 'refrigerators',
        name: 'Refrigerators',
        link: '/products/refrigerators',
        hasRebates: false,
      },
      {
        id: 9,
        handle: 'washers',
        name: 'Washers',
        link: '/products/washers',
        hasRebates: false,
      },
    ],
  },
  {
    id: 6,
    name: 'Temperature Control',
    categories: [
      {
        id: 8,
        handle: 'air_conditioners',
        name: 'Air Conditioners',
        link: '/products/air_conditioners',
        hasRebates: true,
      },
      {
        id: 15,
        handle: 'ceiling_fans',
        name: 'Ceiling Fans',
        link: '/products/ceiling_fans',
        hasRebates: false,
      },
      {
        id: 18,
        handle: 'mini_splits',
        name: 'Mini Split Heat Pumps',
        link: '/products/mini_splits',
        hasRebates: false,
      },
      {
        id: 1,
        handle: 'thermostats',
        name: 'Thermostats',
        link: '/products/thermostats',
        hasRebates: true,
      },
    ],
  },
  {
    id: 9,
    name: 'Batteries, Generators, and EVs',
    categories: [
      {
        id: 3,
        handle: 'ev_chargers',
        name: 'EV Chargers',
        link: '/products/ev_chargers',
        hasRebates: true,
      },
      {
        id: 12,
        handle: 'portable_batteries',
        name: 'Portable Batteries',
        link: '/products/portable_batteries',
        hasRebates: true,
      },
      {
        id: 11,
        handle: 'portable_generators',
        name: 'Portable Generators',
        link: '/products/portable_generators',
        hasRebates: true,
      },
    ],
  },
  {
    id: 10,
    name: 'Home Essentials',
    categories: [
      {
        id: 4,
        handle: 'air_purifiers',
        name: 'Air Purifiers',
        link: '/products/air_purifiers',
        hasRebates: false,
      },
      {
        id: 10,
        handle: 'light_bulbs',
        name: 'Light Bulbs',
        link: '/products/light_bulbs',
        hasRebates: false,
      },
      {
        id: 14,
        handle: 'pool_pumps',
        name: 'Pool Pumps',
        link: '/products/pool_pumps',
        hasRebates: false,
      },
    ],
  },
  {
    id: 8,
    name: 'Water Heating',
    categories: [
      {
        id: 16,
        handle: 'electric_water_heaters',
        name: 'Electric Water Heaters',
        link: '/products/electric_water_heaters',
        hasRebates: true,
      },
      {
        id: 13,
        handle: 'gas_water_heaters',
        name: 'Gas Water Heaters',
        link: '/products/gas_water_heaters',
        hasRebates: true,
      },
    ],
  },
];

import { Box, Container, Typography } from '@mui/material';
import CategoryGroup from './CategoryGroup';

export default function ProductCategories({ groupedCategories = MOCK_CATEGORY_GROUPS }) {
  return (
    <Box p={2}>
      <Box p={2}>
        <Typography component="h1" display="flex" flexDirection="column">
          <Typography
            variant="h4"
            fontSize={{ xs: '0.875rem', sm: '1.125rem' }}
            component="span"
            fontWeight="bold"
            textTransform="uppercase"
            textAlign="center"
            mb={{ xs: 2, md: 2.5 }}
          >
            Top Energy Products
          </Typography>
          <Typography
            variant="h2"
            fontWeight={400}
            fontSize="1.5rem"
            component="span"
            mb={2}
            textAlign="center"
          >
            Shop only the most energy efficient products in your price range.
          </Typography>
        </Typography>
        <Typography fontSize="0.875rem" fontWeight={600} color="primary.main" textAlign="center">
          All products are{' '}
          <Typography component="span" color="blue.500" fontSize="0.875rem" fontWeight={600}>
            ENERGY STAR®
          </Typography>{' '}
          certified where applicable.
        </Typography>
      </Box>

      <Box mt={3} mb={5}>
        {groupedCategories.map((group) => (
          <CategoryGroup categoryGroup={group} key={group.id} />
        ))}
      </Box>
    </Box>
  );
}
