import { Box } from '@mui/material';
import ResourceCard from '../ResourceCard';

export default function ProductDetailsResource({ resource, onDrawerClose, onShowResource }) {
  const handleResourceClick = (e) => {
    // close currently open drawer in favor of a new one to feature the resource
    onDrawerClose();

    const drawerHandler = onShowResource(resource.id);
    drawerHandler(e);
  };

  return (
    <>
      <Box mb={{ xs: 3, sm: 6 }}>
        <ResourceCard resource={resource} onClick={handleResourceClick} />
      </Box>
    </>
  );
}
