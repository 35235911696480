import { Box, Grid, Typography } from '@mui/material';
import Link from '../../components/Link';

const Column = ({ header, linkUrl, handleLinkClick, showLink, children }) => (
  <Grid item xs={12} md={6} display="flex" flexDirection="column" mb={{ xs: 2, md: 0 }}>
    <Typography
      variant="h4"
      color="gray.900"
      component="h2"
      textTransform="uppercase"
      fontSize="1.125rem"
      fontWeight={600}
      mb={5}
    >
      {header}
    </Typography>
    <Box flexGrow={1}>{children}</Box>
    {showLink && (
      <Box
        display={{ xs: 'none', md: 'block' }}
        borderTop="1px solid"
        borderColor="gray.300"
        pt={3}
      >
        <Link
          href={linkUrl}
          textTransform="uppercase"
          onClick={handleLinkClick}
          aria-label={`View more ${header}`}
          sx={{ fontWeight: 600 }}
        >
          View More
        </Link>
      </Box>
    )}
  </Grid>
);

export default Column;
