import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Box, Container } from '@mui/material';
import Link from '../../components/Link';

// Copied from their website, I'm really worried about link breakage
// but at least we're preserving current behavior.
const SVCEGoogleMaps =
  'https://www.google.com/maps/place/333+W+El+Camino+Real+%23330,+Sunnyvale,+CA+94087/@37.3693137,-122.0380819,17z/data=!3m1!4b1!4m5!3m4!1s0x808fb661a8aaf46d:0x4ff90ff1dd52c5eb!8m2!3d37.3693137!4d-122.0358879';

const Column = ({ header, children }) => (
  <Stack spacing={2} flexWrap="wrap">
    <Typography variant="h5" fontWeight="bold" color="common.white">
      {header}
    </Typography>
    {children}
  </Stack>
);

const FooterLink = ({ href, children, text }) => (
  <Link
    external
    href={href}
    sx={{
      color: 'common.white',
      textDecoration: 'none',
    }}
  >
    {children || text}
  </Link>
);

const IconText = ({ icon, text }) => (
  <div className="flexItem">
    {icon}
    <span>{text}</span>
  </div>
);

export const Footer = () => (
  <Box bgcolor="#41535F">
    <Container maxWidth="xl">
      <Grid
        container
        rowSpacing={4}
        columnSpacing={2}
        justifyContent="space-around"
        pt={{ xs: 8, md: 20 }}
        pb={{ xs: 8, md: 20 }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Column header="Contact Us">
            <FooterLink href={SVCEGoogleMaps}>
              <IconText
                icon={<ExploreOutlinedIcon />}
                text="333 W. EL CAMINO REAL, #330 SUNNYVALE, CA 94087"
              />
            </FooterLink>
            <FooterLink href="tel:8444747823">
              <IconText text="1-844-474-SVCE (7823)" icon={<PhoneInTalkOutlinedIcon />} />
            </FooterLink>
            <FooterLink href="mailto:info@svcleanenergy.org">
              <IconText icon={<EmailOutlinedIcon />} text="INFO@SVCLEANENERGY.ORG" />
            </FooterLink>

            <FooterLink href="https://svcleanenergy.org/contact/">
              <Button variant="contained" sx={{ borderRadius: '100px', backgroundColor: '#FFF' }}>
                <div className="flexItem">
                  <span>Contact Us</span>
                  <ChatBubbleOutlineOutlinedIcon
                    sx={{ '&:before': { display: 'inline-block', width: '16px', content: '""' } }}
                  />
                </div>
              </Button>
            </FooterLink>

            <Stack direction="row">
              <FooterLink href="https://www.facebook.com/svcleanenergy/">
                <img
                  style={{ width: '40px', marginRight: '8px' }}
                  src="/static-assets/footer/facebook.svg"
                />
              </FooterLink>
              <FooterLink href="https://twitter.com/svcleanenergy/">
                <img
                  style={{ width: '40px', marginRight: '8px' }}
                  src="/static-assets/footer/twitter.svg"
                />
              </FooterLink>
              <FooterLink href="https://instagram.com/svcleanenergy/">
                <img
                  style={{ width: '40px', marginRight: '8px' }}
                  src="/static-assets/footer/instagram.svg"
                />
              </FooterLink>
              <FooterLink href="https://www.linkedin.com/company/silicon-valley-clean-energy">
                <img
                  style={{ width: '40px', marginRight: '8px' }}
                  src="/static-assets/footer/linkedin.svg"
                />
              </FooterLink>
            </Stack>
          </Column>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Column header="More Info">
            <FooterLink href="https://svcleanenergy.org/faqs/" text="FAQs" />
            <FooterLink href="https://svcleanenergy.org/news-articles/" text="News & Articles" />
            <FooterLink href="https://svcleanenergy.org/jobs/" text="Join Our Team" />
          </Column>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Column header="For Our Community">
            <FooterLink
              href="https://svcleanenergy.org/member-agencies/"
              text="Member Agency Resources"
            />
            <FooterLink
              href="https://svcleanenergy.org/grant-education/"
              text="Grants and Education Opportunities"
            />
            <FooterLink
              href="https://svcleanenergy.org/reach-codes/"
              text="Building Policies & Initiatives"
            />
            <FooterLink
              href="https://svcleanenergy.org/svtec/"
              text="Transportation Electrification"
            />
          </Column>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Column header="Do Business with SVCE">
            <FooterLink href="https://svcleanenergy.org/solicitations/" text="Solicitations" />
            <FooterLink
              href="https://svcleanenergy.org/supplier-diversity/"
              text="Supplier Diversity"
            />
            <FooterLink href="https://svcleanenergy.org/media-center/" text="Media Center" />
            <FooterLink href="https://svcleanenergy.org/innovation/" text="Innovation" />
            <FooterLink
              href="https://svcleanenergy.org/researchandanalysis/"
              text="Research and Analysis"
            />
            <FooterLink href="https://data.svcleanenergy.org/" text="SVCE Data Hive" />
          </Column>
        </Grid>
      </Grid>
    </Container>
  </Box>
);
