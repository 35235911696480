import { Box, Button, Typography } from '@mui/material';
import Filter from './Filter';
import { removeQueryParam, replaceQueryParam } from '../../utils/urlFunctions';
import { generateFiltersUrl, resetFiltersData } from '../../utils/filterFunctions';
import { router, usePage } from '@inertiajs/react';
import { CloseIcon } from '../../assets/icons/icons';
import { cloneDeep } from 'lodash';
import { useState } from 'react';
import MobileFiltersButtons from './MobileFiltersButtons';
import useDeepCompareEffect from '../../hooks/useDeepCompareEffect';
import VisuallyHiddenText from '../VisuallyHiddenText';
import useAriaLiveStatus from '../../hooks/useAriaLiveStatus';

export default function FilterRail({
  filtersData: serverFiltersData,
  isFullScreen,
  onClose,
  title,
  description,
  standaloneFiltersData = [], // for UI components that look/act like filters, but don't affect the `filters` query param in the standard way
}) {
  const { url } = usePage();

  // local version of server data so UI interactions aren't held up by server delays
  // once the server responds, this state is re-synced with the new server data via useEffect
  const [filtersData, setFiltersData] = useState(cloneDeep(serverFiltersData));

  // visually hidden filter status message for screen readers
  const [ariaLiveStatus, setAriaLiveStatus] = useAriaLiveStatus();

  useDeepCompareEffect(() => {
    setFiltersData(cloneDeep(serverFiltersData));
  }, [serverFiltersData]);

  const applyFilters = (newFiltersData) => {
    const filtersUrl = generateFiltersUrl(newFiltersData);

    const newUrl = filtersUrl
      ? replaceQueryParam(url, 'filters', filtersUrl)
      : removeQueryParam(url, 'filters');

    const newPageUrl = replaceQueryParam(newUrl, 'page', 1);

    router.visit(newPageUrl, {
      preserveScroll: true,
      preserveState: true,
    });
  };

  const handleFilterChange = (newFiltersData) => {
    setFiltersData(newFiltersData);

    setAriaLiveStatus('');

    // if in full screen view, don't apply filters on interaction, only when "Update" button is clicked
    if (!isFullScreen) {
      applyFilters(newFiltersData);
    }
  };

  // only called in full screen filters modal, applies batched changes all at once
  const handleUpdateClick = () => {
    applyFilters(filtersData);
    onClose();
  };

  const handleCheckboxChange = (filterId, itemId, checked) => {
    const filtersDataCopy = cloneDeep(filtersData);

    const filter = filtersDataCopy.find(({ id }) => id === filterId);
    const filterItem = filter.items.find(({ id }) => id === itemId);
    filterItem.checked = checked;

    handleFilterChange(filtersDataCopy);
  };

  const handleSliderChange = (filterId, [min, max]) => {
    const filtersDataCopy = cloneDeep(filtersData);

    const filter = filtersDataCopy.find(({ id }) => id === filterId);
    filter.value_min = min;
    filter.value_max = max;

    handleFilterChange(filtersDataCopy);
  };

  const handleSelectChange = (filterId, newValue) => {
    const filtersDataCopy = cloneDeep(filtersData);

    const filter = filtersDataCopy.find(({ id }) => id === filterId);
    filter.value = newValue;

    handleFilterChange(filtersDataCopy);
  };

  const handleNonNumericSliderChange = (filterId, newValue) => {
    const filtersDataCopy = cloneDeep(filtersData);

    const filter = filtersDataCopy.find(({ id }) => id === filterId);
    filter.value = newValue;

    handleFilterChange(filtersDataCopy);
  }

  // set all filter items to default
  const handleResetClick = () => {
    const resetFilters = resetFiltersData(filtersData);

    applyFilters(resetFilters);

    setAriaLiveStatus('Resetting filters, showing all results');

    if (onClose) {
      onClose();
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      width="100%"
      data-testid="filter-rail"
      p={3}
      sx={{
        border: '1px solid',
        borderColor: isFullScreen ? 'transparent' : 'gray.300',
        borderRadius: '4px',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ borderBottom: '1px solid', borderColor: isFullScreen ? 'gray.300' : 'transparent' }}
      >
        {isFullScreen ? (
          <Typography
            variant="h6"
            component="h2"
            fontSize="1.25rem"
            mb={1}
            fontWeight={600}
            id="filter-rail-heading"
          >
            Filter
          </Typography>
        ) : (
          <Box>
            <Typography variant="h6" component="h2" fontSize="1.25rem" mb={1} fontWeight={600}>
              {title}
            </Typography>
            {description && (
              /* Rough edge: This aggressively sets the fontSize of Typography children with
               * no easy way for the child to override it. That complies with the design but might
               * surprise Future Us. */
              <Box sx={{ fontSize: "0.875rem", '& .MuiTypography-root': { fontSize: "0.875rem" } }}>
                {description}
              </Box>
            )}
          </Box>
        )}

        {isFullScreen ? (
          <Button onClick={onClose} sx={{ minWidth: 'auto' }} aria-label="Close filters">
            <CloseIcon />
          </Button>
        ) : (
          <Button
            onClick={handleResetClick}
            aria-label="Reset all filters"
            sx={{
              fontWeight: 600,
              textTransform: 'none',
              color: 'primary.main',
              py: 0,
              px: 0.5,
              minWidth: 'auto',
            }}
          >
            Reset
          </Button>
        )}
      </Box>

      <Box mb={{ xs: 14, md: 0 }}>
        {standaloneFiltersData.map((filter) => (
          <Filter
            key={filter.filterData.id}
            filterData={filter.filterData}
            onCheckboxChange={filter.onCheckboxChange}
            onSliderChange={filter.onSliderChange}
            truncatable={filter.truncatable}
          />
        ))}

        {filtersData.map((filter) => (
          <Filter
            key={filter.id}
            filterData={filter}
            onCheckboxChange={handleCheckboxChange}
            onSliderChange={handleSliderChange}
            onSelectChange={handleSelectChange}
            onNonNumericSliderChange={handleNonNumericSliderChange}
          />
        ))}
      </Box>

      <MobileFiltersButtons handleReset={handleResetClick} handleUpdate={handleUpdateClick} />

      <VisuallyHiddenText aria-live="polite" role="status">
        {ariaLiveStatus}
      </VisuallyHiddenText>
    </Box>
  );
}
