import { Link as InertiaLink } from '@inertiajs/react';
import { Link as MuiLink } from '@mui/material';

export default function ButtonLink({
  href,
  fullWidth,
  external,
  disabled,
  sx = {},
  children,
  ...props
}) {
  return (
    <MuiLink
      component={external ? 'a' : InertiaLink}
      href={href}
      target={external ? '_blank' : '_self'}
      disabled={disabled}
      {...props}
      sx={{
        display: 'block',
        textAlign: 'center',
        width: fullWidth ? '100%' : 'auto',
        px: { xs: fullWidth ? 0 : 2.5, sm: fullWidth ? 0 : 9 },
        py: 2,
        fontWeight: 600,
        color: disabled ? 'gray.600' : 'common.white',
        backgroundColor: disabled ? 'gray.300' : 'primary.main',
        textDecoration: 'none',
        fontSize: { xs: '0.875rem', sm: '1rem' },
        pointerEvents: disabled ? 'none' : 'auto',
        borderRadius: '8px',
        lineHeight: '20px',
        '&:hover': {
          opacity: 0.9,
        },
        ...sx,
      }}
    >
      {children}
    </MuiLink>
  );
}
